/* eslint-disable no-irregular-whitespace */
import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import {
    Container,
    Titre,
    SubTitre,
    Text,
    Button,
    Content,
    ContentInfo,
    BedContent,
    Bed,
    MealContent,
    Meal,
    HalfPager,
    ContentSectionSiegesImageSeat,
} from './styles';

import { SeatsModel } from 'src/components/SeatsModel';

import { MoreLessArrows } from 'src/components/MoreLessArrows';

export const Benefits = () => {
    const [seat5Number, setSeat5Number] = useState<number>(0);
    const [rowNumber, setRowNumber] = useState<number>(0);
    const [demiSectionNumber, setDemiSectionNumber] = useState<number>(0);
    const [sectionNumber, setSectionNumber] = useState<number>(0);

    const { bed, meal } = useStaticQuery(
        graphql`
            query {
                bed: file(relativePath: { eq: "double-bed 1.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 200) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                meal: file(relativePath: { eq: "meal 1.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 200) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        `,
    );

    const bedImageData = bed.childImageSharp.fluid;
    const mealImageData = meal.childImageSharp.fluid;

    const onDownSeat5Number = () => {
        if (seat5Number > 0) {
            setSeat5Number(seat5Number - 1);
        }
    };

    const onDownRowNumber = () => {
        if (rowNumber > 0) {
            setRowNumber(rowNumber - 1);
        }
    };

    const onDownDemiSectionNumber = () => {
        if (demiSectionNumber > 0) {
            setDemiSectionNumber(demiSectionNumber - 1);
        }
    };

    const onDownSectionNumber = () => {
        if (sectionNumber > 0) {
            setSectionNumber(sectionNumber - 1);
        }
    };

    const total =
        seat5Number * 25 + rowNumber * 75 + demiSectionNumber * 600 + sectionNumber * 1200;
    const totalNuit = seat5Number + rowNumber * 3 + demiSectionNumber * 24 + sectionNumber * 48;
    const totalRepas =
        seat5Number * 20 + rowNumber * 60 + demiSectionNumber * 480 + sectionNumber * 960;
    const numberSeat =
        seat5Number * 5 + rowNumber * 15 + demiSectionNumber * 120 + sectionNumber * 240;

    return (
        <Container>
            <HalfPager>
                <Content>
                    <ContentInfo>
                        <Titre number={seat5Number}>5 billets</Titre>
                        <SubTitre number={seat5Number}>25 $</SubTitre>
                    </ContentInfo>
                    <MoreLessArrows
                        onUpPress={() => setSeat5Number(seat5Number + 1)}
                        onDownPress={onDownSeat5Number}
                        number={seat5Number}
                    />
                </Content>
                <Content>
                    <ContentInfo>
                        <Titre number={rowNumber}>Rangée</Titre>
                        <SubTitre number={rowNumber}>75 $</SubTitre>
                    </ContentInfo>
                    <MoreLessArrows
                        onUpPress={() => setRowNumber(rowNumber + 1)}
                        onDownPress={onDownRowNumber}
                        number={rowNumber}
                    />
                </Content>
                <Content>
                    <ContentInfo>
                        <Titre number={demiSectionNumber}>1/2 section</Titre>
                        <SubTitre number={demiSectionNumber}>600 $</SubTitre>
                    </ContentInfo>
                    <MoreLessArrows
                        onUpPress={() => setDemiSectionNumber(demiSectionNumber + 1)}
                        onDownPress={onDownDemiSectionNumber}
                        number={demiSectionNumber}
                    />
                </Content>
                <Content style={{ borderBottom: 0 }}>
                    <ContentInfo>
                        <Titre number={sectionNumber}>Section</Titre>
                        <SubTitre number={sectionNumber}>1 200 $</SubTitre>
                    </ContentInfo>
                    <MoreLessArrows
                        onUpPress={() => setSectionNumber(sectionNumber + 1)}
                        onDownPress={onDownSectionNumber}
                        number={sectionNumber}
                    />
                </Content>

                <Content
                    style={{
                        borderBottom: 0,
                        justifyContent: 'space-between',
                        margin: 'auto',
                        marginTop: 40,
                        width: '100%',
                    }}
                >
                    <ContentInfo style={{ margin: 'auto' }}>
                        <Titre style={{ color: '#CC99FF', width: 'auto' }}>TOTAL</Titre>
                        <SubTitre
                            style={{
                                width: 'auto',
                                fontSize: 'x-large',
                                fontFamily: 'Druk Wide Bold',
                            }}
                        >
                            {total},00 $
                        </SubTitre>
                    </ContentInfo>
                    <SeatsModel number={numberSeat} />
                </Content>

                <Content
                    style={{
                        borderBottom: 0,
                        justifyContent: 'space-between',
                        marginTop: 40,
                        width: '100%',
                    }}
                >
                    <Text
                        style={{
                            width: '40%',
                            textAlign: 'center',
                            margin: 'auto 0px',
                            fontFamily: 'Ace Lift Regular',
                        }}
                    >
                        Votre générosité permet d'offrir
                    </Text>
                    <BedContent style={{ width: 'auto', alignItems: 'center', margin: 'auto' }}>
                        <Bed fluid={bedImageData} />
                        <Text
                            style={{
                                marginTop: 0,
                                textTransform: 'uppercase',
                                textAlign: 'center',
                                fontFamily: 'Ace Lift Regular',
                            }}
                        >
                            {totalNuit} nuit{totalNuit > 1 ? 's' : ''}
                        </Text>
                    </BedContent>
                    <Text
                        style={{
                            width: 50,
                            textAlign: 'center',
                            margin: 'auto 0px',
                            fontFamily: 'Ace Lift Regular',
                        }}
                    >
                        OU
                    </Text>
                    <MealContent style={{ width: 'auto', alignItems: 'center', margin: 'auto' }}>
                        <Meal fluid={mealImageData} />
                        <Text
                            style={{
                                marginTop: 0,
                                textTransform: 'uppercase',
                                textAlign: 'center',
                                fontFamily: 'Ace Lift Regular',
                            }}
                        >
                            {totalRepas} repas
                        </Text>
                    </MealContent>
                </Content>

                <Button
                    href="https://www.jedonneenligne.org/fondationdurefugedesjeunes/FSDR21/"
                    target="_blank"
                >
                    ACHETEZ VOS BILLETS
                </Button>
            </HalfPager>
        </Container>
    );
};
