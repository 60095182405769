import React, { useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Rings } from 'src/components/Rings';

import { Title, Page, Columns, Titre, SubTitre, Box, Row } from './styles';

export const OlympicDonors: React.FC = () => {
    const { googleSheet } = useStaticQuery(
        graphql`
            query {
                googleSheet {
                    billets {
                        nom
                        vendus
                    }
                }
            }
        `,
    );

    const customerLogo = useRef(null);
    const array = googleSheet.billets
        .sort((a, b) => b.vendus - a.vendus)
        .slice(0, 9);

    const createBox = (array) => {
        const items = array.map(({ nom, vendus }, index) => (
            <Columns ref={customerLogo} key={`Columns${nom}${vendus}`} style={{ zIndex: index }}>
                <Titre key={`Titre${nom}${vendus}`}>{nom}</Titre>
                <SubTitre key={`SubTitre${nom}${vendus}`}>
                    {vendus} siège{vendus > 1 && 's'}
                </SubTitre>
            </Columns>
        ));

        const row1 = <Row>{items.slice(0, 3)}</Row>;
        const row2 = <Row>{items.slice(3, 6)}</Row>;
        const row3 = <Row>{items.slice(6, 9)}</Row>;

        return (
            <Box>
                {row1}
                {row2}
                {row3}
            </Box>
        );
    };

    return (
        <Page>
            <Rings />
            <Title>Donateurs olympiques</Title>
            {createBox(array)}
        </Page>
    );
};
