import { Verify } from 'crypto';
import styled from 'styled-components';

export const DoubleStacked = styled.div`
    display: flex;
    flex-direction: row;
    color: #fff;
    width: 40px;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
`;

export const ContentButtonRight = styled.div`
    font-family: 'Ace Lift Bold', sans-serif;
    color: #fff;
    background-color: ${(props) => (props.number > 0 ? '#CC99FF' : '#D1D3D4')};
    border-radius: 0px 4.95671px 4.95671px 0px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    :active {
        background-color: ${(props) => (props.number > 0 ? '#ac8ace' : '#D1D3D4')};
    }

    :hover {
        background-color: ${(props) => (props.number > 0 ? '#ac8ace' : '#ac8ace')};
    }
`;

export const ContentButtonLeft = styled.div`
    font-family: 'Ace Lift Bold', sans-serif;
    color: #fff;
    background-color: ${(props) => (props.number > 0 ? '#CC99FF' : '#646666')};
    border-radius: 4.95671px 0px 0px 4.95671px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${(props) => (props.number > 0 ? 'pointer' : 'auto')};

    :active {
        background-color: ${(props) => (props.number > 0 ? '#ac8ace' : '#646666')};
    }

    :hover {
        background-color: ${(props) => (props.number > 0 ? '#ac8ace' : '#646666')};
    }
`;

export const ContentNumber = styled.div`
    font-family: 'Ace Lift Bold', sans-serif;
    color: #fff;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.670996px solid ${(props) => (props.number > 0 ? '#CC99FF' : '#646666')};
    padding-top: 4px;
`;
