import styled from 'styled-components';

export const StadeContent = styled.div`
    width: 100%;

    svg {
        .cls-1 {
            fill: #2d2d2d;
        }

        .cls-2 {
            fill: #d1d3d4;
        }

        .cls-3 {
            fill: #c4c4c4;
        }

        .cls-4 {
            fill: #abadad;
        }

        .cls-5 {
            fill: #8b8c8c;
        }

        .cls-6 {
            fill: #646666;
        }
        #loges {
            fill: ${(props) => (props.number >= 38400 ? '#E5FF1F' : '#d1d3d4')};
        }
        #section_a_1 {
            fill: ${(props) => (props.number >= 43920 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_2 {
            fill: ${(props) => (props.number >= 44160 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_3 {
            fill: ${(props) => (props.number >= 44400 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_4 {
            fill: ${(props) => (props.number >= 44640 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_5 {
            fill: ${(props) => (props.number >= 44880 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_6 {
            fill: ${(props) => (props.number >= 45120 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_7 {
            fill: ${(props) => (props.number >= 45360 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_8 {
            fill: ${(props) => (props.number >= 45600 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_9 {
            fill: ${(props) => (props.number >= 45840 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_10 {
            fill: ${(props) => (props.number >= 46080 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_11 {
            fill: ${(props) => (props.number >= 46320 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_12 {
            fill: ${(props) => (props.number >= 46560 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_13 {
            fill: ${(props) => (props.number >= 46800 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_14 {
            fill: ${(props) => (props.number >= 47040 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_15 {
            fill: ${(props) => (props.number >= 47280 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_16 {
            fill: ${(props) => (props.number >= 47520 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_17 {
            fill: ${(props) => (props.number >= 47760 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_18 {
            fill: ${(props) => (props.number >= 48000 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_19 {
            fill: ${(props) => (props.number >= 48240 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_20 {
            fill: ${(props) => (props.number >= 48480 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_21 {
            fill: ${(props) => (props.number >= 48000 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_22 {
            fill: ${(props) => (props.number >= 48240 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_23 {
            fill: ${(props) => (props.number >= 48480 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_24 {
            fill: ${(props) => (props.number >= 48720 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_25 {
            fill: ${(props) => (props.number >= 48960 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_26 {
            fill: ${(props) => (props.number >= 49200 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_27 {
            fill: ${(props) => (props.number >= 49440 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_28 {
            fill: ${(props) => (props.number >= 49680 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_29 {
            fill: ${(props) => (props.number >= 49920 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_30 {
            fill: ${(props) => (props.number >= 50160 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_31 {
            fill: ${(props) => (props.number >= 50400 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_32 {
            fill: ${(props) => (props.number >= 50640 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_33 {
            fill: ${(props) => (props.number >= 50880 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_34 {
            fill: ${(props) => (props.number >= 51120 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_35 {
            fill: ${(props) => (props.number >= 51360 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_36 {
            fill: ${(props) => (props.number >= 51600 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_37 {
            fill: ${(props) => (props.number >= 51840 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_38 {
            fill: ${(props) => (props.number >= 52080 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_39 {
            fill: ${(props) => (props.number >= 52320 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_40 {
            fill: ${(props) => (props.number >= 52560 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_41 {
            fill: ${(props) => (props.number >= 52800 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_42 {
            fill: ${(props) => (props.number >= 53040 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_43 {
            fill: ${(props) => (props.number >= 53280 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_44 {
            fill: ${(props) => (props.number >= 53520 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_45 {
            fill: ${(props) => (props.number >= 53760 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_46 {
            fill: ${(props) => (props.number >= 54000 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_47 {
            fill: ${(props) => (props.number >= 54240 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_48 {
            fill: ${(props) => (props.number >= 54480 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_49 {
            fill: ${(props) => (props.number >= 55200 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_50 {
            fill: ${(props) => (props.number >= 55440 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_51 {
            fill: ${(props) => (props.number >= 55680 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_52 {
            fill: ${(props) => (props.number >= 55920 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_a_53 {
            fill: ${(props) => (props.number >= 56160 ? '#E5FF1F' : '#c4c4c4')};
        }

        #section_b_1 {
            fill: ${(props) => (props.number >= 30960 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_2 {
            fill: ${(props) => (props.number >= 31200 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_3 {
            fill: ${(props) => (props.number >= 31440 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_4 {
            fill: ${(props) => (props.number >= 31680 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_5 {
            fill: ${(props) => (props.number >= 31920 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_6 {
            fill: ${(props) => (props.number >= 32160 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_7 {
            fill: ${(props) => (props.number >= 32400 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_8 {
            fill: ${(props) => (props.number >= 32640 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_9 {
            fill: ${(props) => (props.number >= 32880 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_10 {
            fill: ${(props) => (props.number >= 33360 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_11 {
            fill: ${(props) => (props.number >= 33600 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_12 {
            fill: ${(props) => (props.number >= 33840 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_13 {
            fill: ${(props) => (props.number >= 34080 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_14 {
            fill: ${(props) => (props.number >= 34320 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_15 {
            fill: ${(props) => (props.number >= 34560 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_16 {
            fill: ${(props) => (props.number >= 34800 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_17 {
            fill: ${(props) => (props.number >= 35040 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_18 {
            fill: ${(props) => (props.number >= 35280 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_19 {
            fill: ${(props) => (props.number >= 35520 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_20 {
            fill: ${(props) => (props.number >= 35760 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_21 {
            fill: ${(props) => (props.number >= 36000 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_22 {
            fill: ${(props) => (props.number >= 36240 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_23 {
            fill: ${(props) => (props.number >= 36480 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_24 {
            fill: ${(props) => (props.number >= 36720 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_25 {
            fill: ${(props) => (props.number >= 36960 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_26 {
            fill: ${(props) => (props.number >= 37200 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_27 {
            fill: ${(props) => (props.number >= 37440 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_28 {
            fill: ${(props) => (props.number >= 37680 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_29 {
            fill: ${(props) => (props.number >= 37920 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_30 {
            fill: ${(props) => (props.number >= 38160 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_31 {
            fill: ${(props) => (props.number >= 38400 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_32 {
            fill: ${(props) => (props.number >= 38640 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_33 {
            fill: ${(props) => (props.number >= 38880 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_34 {
            fill: ${(props) => (props.number >= 39120 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_35 {
            fill: ${(props) => (props.number >= 39360 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_36 {
            fill: ${(props) => (props.number >= 39600 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_37 {
            fill: ${(props) => (props.number >= 39840 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_38 {
            fill: ${(props) => (props.number >= 40080 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_39 {
            fill: ${(props) => (props.number >= 40320 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_40 {
            fill: ${(props) => (props.number >= 40560 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_41 {
            fill: ${(props) => (props.number >= 40800 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_42 {
            fill: ${(props) => (props.number >= 41040 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_43 {
            fill: ${(props) => (props.number >= 41280 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_44 {
            fill: ${(props) => (props.number >= 41520 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_45 {
            fill: ${(props) => (props.number >= 41760 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_46 {
            fill: ${(props) => (props.number >= 42000 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_47 {
            fill: ${(props) => (props.number >= 42240 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_48 {
            fill: ${(props) => (props.number >= 42480 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_49 {
            fill: ${(props) => (props.number >= 42720 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_50 {
            fill: ${(props) => (props.number >= 42960 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_51 {
            fill: ${(props) => (props.number >= 43200 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_52 {
            fill: ${(props) => (props.number >= 43440 ? '#E5FF1F' : '#abadad')};
        }

        #section_b_53 {
            fill: ${(props) => (props.number >= 43680 ? '#E5FF1F' : '#abadad')};
        }

        #section_c_1 {
            fill: ${(props) => (props.number >= 12960 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_2 {
            fill: ${(props) => (props.number >= 13200 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_3 {
            fill: ${(props) => (props.number >= 13440 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_4 {
            fill: ${(props) => (props.number >= 13680 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_5 {
            fill: ${(props) => (props.number >= 13920 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_6 {
            fill: ${(props) => (props.number >= 14160 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_7 {
            fill: ${(props) => (props.number >= 14400 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_8 {
            fill: ${(props) => (props.number >= 14640 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_9 {
            fill: ${(props) => (props.number >= 14880 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_10 {
            fill: ${(props) => (props.number >= 15120 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_11 {
            fill: ${(props) => (props.number >= 15360 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_12 {
            fill: ${(props) => (props.number >= 15600 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_13 {
            fill: ${(props) => (props.number >= 15840 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_14 {
            fill: ${(props) => (props.number >= 16080 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_15 {
            fill: ${(props) => (props.number >= 16320 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_16 {
            fill: ${(props) => (props.number >= 16560 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_17 {
            fill: ${(props) => (props.number >= 16800 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_18 {
            fill: ${(props) => (props.number >= 17040 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_19 {
            fill: ${(props) => (props.number >= 17280 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_20 {
            fill: ${(props) => (props.number >= 17520 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_21 {
            fill: ${(props) => (props.number >= 17760 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_22 {
            fill: ${(props) => (props.number >= 18000 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_23 {
            fill: ${(props) => (props.number >= 18240 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_24 {
            fill: ${(props) => (props.number >= 18480 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_25 {
            fill: ${(props) => (props.number >= 18720 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_26 {
            fill: ${(props) => (props.number >= 18960 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_27 {
            fill: ${(props) => (props.number >= 19200 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_28 {
            fill: ${(props) => (props.number >= 19440 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_29 {
            fill: ${(props) => (props.number >= 19680 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_30 {
            fill: ${(props) => (props.number >= 19920 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_31 {
            fill: ${(props) => (props.number >= 20160 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_32 {
            fill: ${(props) => (props.number >= 20400 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_33 {
            fill: ${(props) => (props.number >= 20640 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_34 {
            fill: ${(props) => (props.number >= 20880 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_35 {
            fill: ${(props) => (props.number >= 21120 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_36 {
            fill: ${(props) => (props.number >= 21360 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_37 {
            fill: ${(props) => (props.number >= 21600 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_38 {
            fill: ${(props) => (props.number >= 21840 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_39 {
            fill: ${(props) => (props.number >= 22080 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_40 {
            fill: ${(props) => (props.number >= 22320 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_41 {
            fill: ${(props) => (props.number >= 22560 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_42 {
            fill: ${(props) => (props.number >= 22800 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_43 {
            fill: ${(props) => (props.number >= 23040 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_44 {
            fill: ${(props) => (props.number >= 23280 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_45 {
            fill: ${(props) => (props.number >= 23520 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_46 {
            fill: ${(props) => (props.number >= 23760 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_47 {
            fill: ${(props) => (props.number >= 24000 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_48 {
            fill: ${(props) => (props.number >= 24240 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_49 {
            fill: ${(props) => (props.number >= 24480 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_50 {
            fill: ${(props) => (props.number >= 24720 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_51 {
            fill: ${(props) => (props.number >= 24960 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_52 {
            fill: ${(props) => (props.number >= 25200 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_c_53 {
            fill: ${(props) => (props.number >= 25440 ? '#E5FF1F' : '#8b8c8c')};
        }

        #section_d_1 {
            fill: ${(props) => (props.number >= 240 ? '#E5FF1F' : '#646666')};
        }

        #section_d_2 {
            fill: ${(props) => (props.number >= 480 ? '#E5FF1F' : '#646666')};
        }

        #section_d_3 {
            fill: ${(props) => (props.number >= 720 ? '#E5FF1F' : '#646666')};
        }

        #section_d_4 {
            fill: ${(props) => (props.number >= 960 ? '#E5FF1F' : '#646666')};
        }

        #section_d_5 {
            fill: ${(props) => (props.number >= 1200 ? '#E5FF1F' : '#646666')};
        }

        #section_d_6 {
            fill: ${(props) => (props.number >= 1440 ? '#E5FF1F' : '#646666')};
        }

        #section_d_7 {
            fill: ${(props) => (props.number >= 1680 ? '#E5FF1F' : '#646666')};
        }

        #section_d_8 {
            fill: ${(props) => (props.number >= 1920 ? '#E5FF1F' : '#646666')};
        }

        #section_d_9 {
            fill: ${(props) => (props.number >= 2160 ? '#E5FF1F' : '#646666')};
        }

        #section_d_10 {
            fill: ${(props) => (props.number >= 2400 ? '#E5FF1F' : '#646666')};
        }

        #section_d_11 {
            fill: ${(props) => (props.number >= 2640 ? '#E5FF1F' : '#646666')};
        }

        #section_d_12 {
            fill: ${(props) => (props.number >= 2880 ? '#E5FF1F' : '#646666')};
        }

        #section_d_13 {
            fill: ${(props) => (props.number >= 3120 ? '#E5FF1F' : '#646666')};
        }

        #section_d_14 {
            fill: ${(props) => (props.number >= 3360 ? '#E5FF1F' : '#646666')};
        }

        #section_d_15 {
            fill: ${(props) => (props.number >= 3600 ? '#E5FF1F' : '#646666')};
        }

        #section_d_16 {
            fill: ${(props) => (props.number >= 3840 ? '#E5FF1F' : '#646666')};
        }

        #section_d_17 {
            fill: ${(props) => (props.number >= 4080 ? '#E5FF1F' : '#646666')};
        }

        #section_d_18 {
            fill: ${(props) => (props.number >= 4320 ? '#E5FF1F' : '#646666')};
        }

        #section_d_19 {
            fill: ${(props) => (props.number >= 4560 ? '#E5FF1F' : '#646666')};
        }

        #section_d_20 {
            fill: ${(props) => (props.number >= 4800 ? '#E5FF1F' : '#646666')};
        }

        #section_d_21 {
            fill: ${(props) => (props.number >= 5040 ? '#E5FF1F' : '#646666')};
        }

        #section_d_22 {
            fill: ${(props) => (props.number >= 5280 ? '#E5FF1F' : '#646666')};
        }

        #section_d_23 {
            fill: ${(props) => (props.number >= 5520 ? '#E5FF1F' : '#646666')};
        }

        #section_d_24 {
            fill: ${(props) => (props.number >= 5760 ? '#E5FF1F' : '#646666')};
        }

        #section_d_25 {
            fill: ${(props) => (props.number >= 6000 ? '#E5FF1F' : '#646666')};
        }

        #section_d_26 {
            fill: ${(props) => (props.number >= 6240 ? '#E5FF1F' : '#646666')};
        }

        #section_d_27 {
            fill: ${(props) => (props.number >= 6480 ? '#E5FF1F' : '#646666')};
        }

        #section_d_28 {
            fill: ${(props) => (props.number >= 6720 ? '#E5FF1F' : '#646666')};
        }

        #section_d_29 {
            fill: ${(props) => (props.number >= 6960 ? '#E5FF1F' : '#646666')};
        }

        #section_d_30 {
            fill: ${(props) => (props.number >= 7200 ? '#E5FF1F' : '#646666')};
        }

        #section_d_31 {
            fill: ${(props) => (props.number >= 7440 ? '#E5FF1F' : '#646666')};
        }

        #section_d_32 {
            fill: ${(props) => (props.number >= 7680 ? '#E5FF1F' : '#646666')};
        }

        #section_d_33 {
            fill: ${(props) => (props.number >= 7920 ? '#E5FF1F' : '#646666')};
        }

        #section_d_34 {
            fill: ${(props) => (props.number >= 8160 ? '#E5FF1F' : '#646666')};
        }

        #section_d_35 {
            fill: ${(props) => (props.number >= 8400 ? '#E5FF1F' : '#646666')};
        }

        #section_d_36 {
            fill: ${(props) => (props.number >= 8640 ? '#E5FF1F' : '#646666')};
        }

        #section_d_37 {
            fill: ${(props) => (props.number >= 8880 ? '#E5FF1F' : '#646666')};
        }

        #section_d_38 {
            fill: ${(props) => (props.number >= 9120 ? '#E5FF1F' : '#646666')};
        }

        #section_d_39 {
            fill: ${(props) => (props.number >= 9360 ? '#E5FF1F' : '#646666')};
        }

        #section_d_40 {
            fill: ${(props) => (props.number >= 9600 ? '#E5FF1F' : '#646666')};
        }

        #section_d_41 {
            fill: ${(props) => (props.number >= 9840 ? '#E5FF1F' : '#646666')};
        }

        #section_d_42 {
            fill: ${(props) => (props.number >= 10080 ? '#E5FF1F' : '#646666')};
        }

        #section_d_43 {
            fill: ${(props) => (props.number >= 10320 ? '#E5FF1F' : '#646666')};
        }

        #section_d_44 {
            fill: ${(props) => (props.number >= 10560 ? '#E5FF1F' : '#646666')};
        }

        #section_d_45 {
            fill: ${(props) => (props.number >= 10800 ? '#E5FF1F' : '#646666')};
        }

        #section_d_46 {
            fill: ${(props) => (props.number >= 11040 ? '#E5FF1F' : '#646666')};
        }

        #section_d_47 {
            fill: ${(props) => (props.number >= 11280 ? '#E5FF1F' : '#646666')};
        }

        #section_d_48 {
            fill: ${(props) => (props.number >= 11520 ? '#E5FF1F' : '#646666')};
        }

        #section_d_49 {
            fill: ${(props) => (props.number >= 11760 ? '#E5FF1F' : '#646666')};
        }

        #section_d_50 {
            fill: ${(props) => (props.number >= 12000 ? '#E5FF1F' : '#646666')};
        }

        #section_d_51 {
            fill: ${(props) => (props.number >= 12240 ? '#E5FF1F' : '#646666')};
        }

        #section_d_52 {
            fill: ${(props) => (props.number >= 12480 ? '#E5FF1F' : '#646666')};
        }

        #section_d_53 {
            fill: ${(props) => (props.number >= 12720 ? '#E5FF1F' : '#646666')};
        }
    }

    @media (max-width: 1440px) {
        height: 530px;
    }

    @media (max-width: 1024px) {
        height: 550px;
    }

    @media (max-width: 768px) {
        height: 550px;
    }

    @media (max-width: 668px) {
        height: 300px;
    }

    @media (max-width: 425px) {
        height: 300px;
    }

    @media (max-width: 375px) {
        height: 300px;
        margin: auto;
    }

    @media (max-width: 320px) {
        width: 100%;
        height: 300px;
        margin-left: -8px;
    }
`;
