import styled from 'styled-components';
import Img from 'gatsby-image';

export const Page = styled.div`
    min-height: 85vh;
    width: 100%;
    background-color: #191919;
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;

    @media (max-height: 600px) {
        padding: 40px 20px;
    }

    @media (max-width: 768px) {
        flex-direction: column;
    }
    @media (max-width: 450px) {
        padding: 40px 20px;
    }
`;

export const Logo = styled(Img)`
    width: 150%;
    margin-left: -75px;

    @media (max-height: 600px) {
        width: 100%;
        margin-left: 0px;
    }

    @media (max-width: 500px) {
        width: 100%;
        margin-left: 0px;
    }
`;

export const Container = styled.div`
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 40%;

    @media (max-width: 1024px) {
        margin: auto;
    }
    @media (max-width: 768px) {
        width: 90%;
    }
`;

export const ContainerStade = styled.div`
    width: 800px;

    @media (max-width: 1440px) {
        width: 40%;
        height: 530px;
    }

    @media (max-width: 1440px) {
        width: 45%;
        height: 530px;
    }

    @media (max-width: 1024px) {
        width: 60%;
        height: 550px;
    }

    @media (max-width: 768px) {
        width: 80%;
        height: 550px;
    }

    @media (max-width: 668px) {
        width: 80%;
        height: 300px;
    }

    @media (max-width: 425px) {
        width: 80%;
        height: 300px;
    }

    @media (max-width: 375px) {
        width: 92%;
        height: 300px;
        margin: auto;
    }

    @media (max-width: 320px) {
        width: 300px;
        height: 300px;
        margin-left: -8px;
    }
`;

export const ContentSeats = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;

    @media (max-width: 1024px) {
        flex-direction: row;
    }

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const Info = styled(Img)`
    width: 570px;

    @media (max-height: 950px) {
        width: 500px;
    }

    @media (max-height: 850px) {
        width: 400px;
    }

    @media (max-height: 700px) {
        width: 350px;
    }

    @media (max-height: 600px) {
        width: 300px;
    }

    @media (max-width: 1024px) {
        width: 300px;
    }

    @media (max-width: 768px) {
        display: none;
    }
`;

export const Titre = styled.div`
    width: 325px;
    font-weight: 900;
    font-size: x-large;
    margin: 0px auto;
    line-height: 34px;
    color: ${(props) => (props.number > 0 ? '#CC99FF' : 'white')};
    font-family: 'Druk Wide Bold';
    text-transform: uppercase;

    @media (max-height: 600px) {
        width: 270px;
        font-size: large;
        line-height: 24px;
    }

    @media (max-width: 375px) {
        font-size: large;
    }

    @media (max-width: 320px) {
        font-size: medium;
    }
`;

export const SubTitre = styled.div`
    width: 325px;
    font-weight: 900;
    font-size: small;
    margin: 0px auto;
    color: ${(props) => (props.number > 0 ? '#CC99FF' : 'white')};
    font-family: 'Ace Lift Bold';
    text-transform: uppercase;

    @media (max-height: 600px) {
        margin: 0px auto;
        width: 270px;
        font-size: x-small;
    }
`;

export const SeatsTaked = styled.div`
    font-weight: 900;
    font-size: xxx-large;
    margin: auto;
    color: #e5ff1f;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-left: 175px;
    font-family: 'Ace Lift Regular', sans-serif;
    top: calc(-60%);

    @media (max-width: 2560px) {
        left: 10%;
        top: calc(-45vh);
    }

    @media (max-width: 1440px) {
        left: 10%;
        top: calc(-50%);
    }

    @media (max-width: 1024px) {
        top: calc(-55%);
    }

    @media (max-width: 768px) {
        top: calc(-55%);
    }

    @media (max-width: 668px) {
        top: calc(-20%);
    }

    @media (max-width: 425px) {
        top: calc(-55%);
        left: -10%;
    }

    @media (max-width: 375px) {
        top: calc(-55%);
        left: -10%;
    }

    @media (max-width: 360px) {
        top: calc(-55%);
        left: -10%;
    }

    @media (max-width: 320px) {
        top: calc(-55%);
        left: -10%;
    }
`;

export const Text = styled.div`
    width: 100%;
    font-size: medium;
    margin-top: 10px;
    color: white;

    @media (max-height: 600px) {
        margin-top: 5px;
        font-size: small;
    }
`;

export const Slash = styled.div`
    font-weight: 900;
    font-size: xxx-large;
    color: white;
    width: auto;
    white-space: nowrap;

    @media (max-width: 1440px) {
        font-size: xxx-large;
        max-width: 250px;
    }

    @media (max-width: 1024px) {
        font-size: xxx-large;
        max-width: 250px;
    }

    @media (max-width: 768px) {
        font-size: xxx-large;
        max-width: 250px;
    }

    @media (max-height: 600px) {
        font-size: xx-large;
    }

    @media (max-width: 425px) {
        font-size: x-large;
        max-width: 150px;
    }

    @media (max-width: 375px) {
        font-size: x-large;
        max-width: 150px;
    }

    @media (max-width: 360px) {
        font-size: x-large;
        max-width: 150px;
    }
`;

export const Content = styled.div`
    width: 100%;
    border-bottom: 1px solid #7186c7;
    padding-bottom: 5px;
    padding-top: 5px;
    display: flex;
    flex-direction: row;
    margin: auto;

    @media (max-width: 1440px) {
        width: 75%;
    }

    @media (max-width: 1024px) {
        width: 90%;
    }

    @media (max-width: 768px) {
        width: 70%;
    }

    @media (max-width: 425px) {
        width: 100%;
    }
`;

export const ContentInfo = styled.div`
    width: 50%;
    padding-bottom: 5px;
    display: flex;
    flex-direction: column;
`;

export const ContentText = styled.div`
    width: 100%;
    padding-top: 7px;
    text-align: left;
    width: 200px;
    border-bottom: 0px solid #7186c7;
    display: flex;
    flex-direction: column;
    align-self: flex-start;

    @media (max-height: 600px) {
        font-size: xx-large;
        padding-top: 0px;
    }

    @media (max-width: 425px) {
        font-size: x-large;
        padding-top: 3px;
    }

    @media (max-width: 375px) {
        font-size: x-large;
    }

    @media (max-width: 360px) {
        font-size: x-large;
    }
`;

export const StadeText = styled.div`
    width: 100%;
    font-size: medium;
    margin-top: 0px;
    color: white;
    width: auto;

    @media (max-width: 425px) {
        font-size: x-small;
        margin-top: 0px;
    }

    @media (max-width: 375px) {
        font-size: x-small;
        margin-top: 0px;
    }

    @media (max-width: 360px) {
        font-size: x-small;
        margin-top: 0px;
    }
`;

export const Label = styled.label`
    font-weight: 900;
    font-size: xx-large;
    margin: 0px auto;
    line-height: 34px;
    color: #7186c7;

    @media (max-height: 600px) {
        font-size: large;
        line-height: 24px;
    }
`;

export const InputLabel = styled.label`
    color: white;

    @media (max-width: 1000px) {
        display: none !important;
    }
`;

export const Input = styled.input`
    color: white;

    @media (max-width: 1000px) {
        display: none !important;
    }
`;

export const Button = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 500px;
    width: 100%;
    height: 50px;
    margin-top: 20px;
    background-color: #cc99ff;
    font-weight: 900;
    font-size: large;
    color: initial;
    text-decoration: none;
    margin: auto;
    margin-top: 40px;
    font-family: 'Ace Lift Regular', sans-serif;
    cursor: pointer;

    :active {
        background-color: #ac8ace;
    }

    @media (max-height: 600px) {
        font-size: medium;
        height: 30px;
        margin-top: 10px;
        width: auto;
    }

    @media (max-width: 425px) {
        font-size: medium;
        margin-top: 10px;
        width: auto;
    }
`;

export const HalfPager = styled.div`
    width: auto;
    z-index: 100;
`;

export const Title = styled.div`
    width: 70%;
    margin: 0px auto;

    @media (max-width: 1024px) {
        width: 80%;
    }

    @media (max-width: 768px) {
        margin: 0px auto;
        width: 90%;
    }
`;

export const MealContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const Meal = styled(Img)`
    width: 46px;
    height: 46px;

    @media (max-width: 768px) {
        width: 30.87px;
        height: 30.87px;
    }
`;

export const BedContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const Bed = styled(Img)`
    width: 46px;
    height: 46px;

    @media (max-width: 768px) {
        width: 30.87px;
        height: 30.87px;
    }
`;

export const ContentSeat = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ContentSeatRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    width: auto;
`;

export const Seat = styled(Img)`
    width: 6px;
    height: 6px;
    margin: 1px;
`;

export const SelectSeat = styled.img`
    width: 100%;
    margin-bottom: 8vh;
`;
