import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import {
    Page,
    ContentSeats,
    Title,
    ContainerStade,
    SeatsTaked,
    ContentText,
    SelectSeat,
    StadeText,
    Slash,
} from './styles';
import { Benefits } from './Benefits';

import { Stade } from 'src/components/Stade';

import selectSeat from './select-your-seat.svg';

export const Seats: React.FC = () => {
    const { googleSheet } = useStaticQuery(
        graphql`
            query {
                googleSheet {
                    billets {
                        nom
                        vendus
                    }
                }
            }
        `,
    );

    const seatsTakedNumber = googleSheet.billets.reduce((accumulator, item) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/restrict-plus-operands
        return accumulator + item.vendus;
    }, 0);

    let seatsTakedString = `${seatsTakedNumber}`;
    if (seatsTakedNumber > 1000) {
        const number = seatsTakedNumber / 1000;
        seatsTakedString = `${number.toPrecision(2)} K`;
    }

    return (
        <Page id="Seats">
            <Title>
                <SelectSeat src={selectSeat} />
            </Title>
            <ContentSeats>
                <ContainerStade>
                    <Stade number={seatsTakedNumber} />
                    <SeatsTaked>
                        <Slash style={{ color: '#E5FF1F' }}>{seatsTakedString}</Slash>{' '}
                        <Slash style={{ textAlign: 'center', width: 50 }}>/</Slash>{' '}
                        <ContentText>
                            <StadeText>56 160</StadeText>
                            <StadeText>PLACES</StadeText>
                        </ContentText>
                    </SeatsTaked>
                </ContainerStade>
                <Benefits />
            </ContentSeats>
        </Page>
    );
};
