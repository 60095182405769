import React from 'react';

import { TakedSeats } from 'src/components/TakedSeats';

import { Hero } from 'src/components/Hero';
import { Information } from 'src/components/Information';
import { Seats } from 'src/components/Seats';
import { OlympicDonors } from 'src/components/OlympicDonors';
import { MoreInfo } from 'src/components/MoreInfo';

import SEO from 'src/components/SEO';


// Page component
const HomePage: React.FC = () => {
    return (
        <>
            <SEO
                title="NO show du refuge"
                description="Le Refuge des Jeunes de Montréal est un accueil de jour, de soir et de nuit
                    fondé en 1989. Sa mission est de venir en aide à des jeunes hommes sans-abri et
                    en difficulté de 17 à 25 ans. Le Refuge offre les services suivants : accueil,
                    références, suivi psychosocial de jour et logement social avec soutien
                    communautaire (21 unités). Le Refuge a accueilli en 30 ans 21 039 jeunes hommes
                    dont plus de 165 d’entre eux ont eu accès à un logement social avec soutien
                    communautaire. Ces jeunes viennent de partout : de Montréal, du Québec, d’autres
                    provinces canadiennes et d’autres pays. Toutes les actions du Refuge visent leur
                    intégration et l’amélioration de leurs conditions de vie."
            />
            <Hero />
            <Information />
            <TakedSeats />
            <Seats />
            <OlympicDonors />
            <MoreInfo />
            <div style={{ marginTop: 40 }}></div>
        </>
    );
};

export default HomePage;
