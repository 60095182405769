import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import edition from './edition-olympique.gif';

import { AcceuilBG, Logo, Container, Title, Button, Edition, Header } from './styles';

export const Hero: React.FC = () => {
    const { stade, logo, title } = useStaticQuery(
        graphql`
            query {
                stade: file(relativePath: { eq: "stade.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 1440) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                logo: file(relativePath: { eq: "logo-refuge-des-jeunes.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                title: file(relativePath: { eq: "section-1-main-text.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 1440) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        `,
    );

    const stadeImageData = stade.childImageSharp.fluid;
    const logoImageData = logo.childImageSharp.fluid;
    const titleImageData = title.childImageSharp.fluid;

    return (
        <AcceuilBG fluid={stadeImageData}>
            <Header>
                <Edition>
                    {/* eslint-disable-next-line jsx-a11y/alt-text */}
                    <img src={edition} />
                </Edition>
                <Logo fluid={logoImageData} style={{ overflow: 'visible' }} />
            </Header>
            <Container>
                <Title fluid={titleImageData}></Title>
            </Container>
            <Button onClick={() => document.getElementById('Seats').scrollIntoView()}>
                ACHETEZ VOS BILLETS
            </Button>
        </AcceuilBG>
    );
};
