import React from 'react';

import { StadeContent } from './styles';

type Props = {
    number: number;
};

export const Stade: React.FC<Props> = (props: Props) => {
    const { number } = props;

    return (
        <StadeContent number={number}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 467.45 455.87">
                <defs>
                    <style></style>
                </defs>
                <g id="stade-olympique-carte">
                    <path
                        id="base"
                        className="cls-1"
                        d="M445.83,356.62l5.43-5.68L434,335l9.31-9.89L428.23,312l15.07-10.86L459.79,266l-.18-38.54h0v-2h0l.18-38.54L443.3,151.79l-15.07-10.87,15.07-13.19L434,117.84l17.27-15.91-5.43-5.68,20-17.56C417.22,30.49,345.41,0,265.32,0,118.79,0,0,102.05,0,227.94S118.79,455.87,265.32,455.87c81,0,153.46-31.17,202.13-80.29Z"
                    />
                    <path
                        id="loges"
                        className="cls-2"
                        d="M264.73,402.22C149,402.22,54.84,324,54.84,227.94S149,53.65,264.73,53.65c60.71,0,119.35,23,160.88,63.09l.07.07,8.58,8.73-5.71,5.6L420,122.46C380,83.82,323.37,61.65,264.73,61.65c-111.33,0-201.89,74.6-201.89,166.29S153.4,394.22,264.73,394.22c59.35,0,116.65-22.87,157.19-62.75,0,0,4.74-4.74,8.42-9.29l6.22,5c-3.95,4.9-8.81,9.75-9,9.95C385.5,378.51,326.16,402.22,264.73,402.22Z"
                    />
                    <g id="sections_a">
                        <polygon
                            id="section_a_1"
                            className="cls-3"
                            points="372.43 308.23 388.41 326.3 379.2 333.72 364.93 314.28 372.43 308.23"
                        />
                        <polygon
                            id="section_a_2"
                            className="cls-3"
                            points="362.49 316.03 377.06 335.25 367.32 341.96 354.55 321.5 362.49 316.03"
                        />
                        <polygon
                            id="section_a_3"
                            className="cls-3"
                            points="352.15 322.94 365.02 343.33 354.73 349.18 343.78 327.7 352.15 322.94"
                        />
                        <polygon
                            id="section_a_4"
                            className="cls-3"
                            points="341.24 328.93 352.13 350.44 341.33 355.3 332.46 332.88 341.24 328.93"
                        />
                        <polygon
                            id="section_a_5"
                            className="cls-3"
                            points="329.53 333.97 338.27 356.44 327.05 360.22 320.41 337.04 329.53 333.97"
                        />
                        <polygon
                            id="section_a_6"
                            className="cls-3"
                            points="317.29 337.81 324.63 360.78 313.19 363.86 307.99 340.32 317.29 337.81"
                        />
                        <polygon
                            id="section_a_7"
                            className="cls-3"
                            points="304.95 341.01 311.29 364.27 299.73 366.85 295.56 343.11 304.95 341.01"
                        />
                        <polygon
                            id="section_a_8"
                            className="cls-3"
                            points="292.31 343.69 297.28 367.27 285.59 369.17 282.81 345.23 292.31 343.69"
                        />
                        <polygon
                            id="section_a_9"
                            className="cls-3"
                            points="279.39 345.62 282.62 369.49 270.82 370.54 269.8 346.46 279.39 345.62"
                        />
                        <polygon
                            id="section_a_10"
                            className="cls-3"
                            points="266.66 346.57 267.85 370.63 256 370.67 257.03 346.6 266.66 346.57"
                        />
                        <polygon
                            id="section_a_11"
                            className="cls-3"
                            points="253.37 346.34 253.03 370.43 241.2 369.72 243.76 345.76 253.37 346.34"
                        />
                        <polygon
                            id="section_a_12"
                            className="cls-3"
                            points="239.87 345.25 237.93 369.26 226.17 367.77 230.32 344.04 239.87 345.25"
                        />
                        <polygon
                            id="section_a_13"
                            className="cls-3"
                            points="226.77 343.31 223.19 367.13 211.56 364.83 217.33 341.44 226.77 343.31"
                        />
                        <polygon
                            id="section_a_14"
                            className="cls-3"
                            points="214.11 340.54 208.84 364.04 197.4 360.91 204.83 338 214.11 340.54"
                        />
                        <polygon
                            id="section_a_15"
                            className="cls-3"
                            points="201.91 336.94 194.92 359.98 183.74 356.02 192.84 333.72 201.91 336.94"
                        />
                        <polygon
                            id="section_a_16"
                            className="cls-3"
                            points="190.2 332.52 181.48 354.96 170.63 350.15 181.41 328.62 190.2 332.52"
                        />
                        <polygon
                            id="section_a_17"
                            className="cls-3"
                            points="179.02 327.29 168.55 348.97 158.12 343.32 170.56 322.71 179.02 327.29"
                        />
                        <polygon
                            id="section_a_18"
                            className="cls-3"
                            points="168.37 321.25 156.19 342.01 146.25 335.54 160.31 316 168.37 321.25"
                        />
                        <polygon
                            id="section_a_19"
                            className="cls-3"
                            points="158.29 314.4 144.45 334.1 135.06 326.82 150.69 308.51 158.29 314.4"
                        />
                        <polygon
                            id="section_a_20"
                            className="cls-3"
                            points="148.81 306.75 133.37 325.22 124.63 317.18 141.73 300.25 148.81 306.75"
                        />
                        <polygon
                            id="section_a_21"
                            className="cls-3"
                            points="140.04 298.39 123 315.39 115.02 306.6 133.58 291.27 140.04 298.39"
                        />
                        <polygon
                            id="section_a_22"
                            className="cls-3"
                            points="132.05 289.25 113.43 304.49 106.35 294.95 126.32 281.53 132.05 289.25"
                        />
                        <polygon
                            id="section_a_23"
                            className="cls-3"
                            points="125 279.36 104.85 292.52 98.83 282.27 120.13 271.08 125 279.36"
                        />
                        <polygon
                            id="section_a_24"
                            className="cls-3"
                            points="119.04 268.77 97.5 279.48 92.71 268.6 115.17 259.97 119.04 268.77"
                        />
                        <polygon
                            id="section_a_25"
                            className="cls-3"
                            points="114.33 257.47 91.62 265.4 88.24 254 111.6 248.26 114.33 257.47"
                        />
                        <polygon
                            id="section_a_26"
                            className="cls-3"
                            points="111.04 245.47 87.48 250.3 85.64 238.55 109.56 235.98 111.04 245.47"
                        />
                        <polygon
                            id="section_a_27"
                            className="cls-3"
                            points="109.35 233.08 85.35 234.64 85.14 222.75 109.19 223.48 109.35 233.08"
                        />
                        <polygon
                            id="section_a_28"
                            className="cls-3"
                            points="109.53 221.43 85.54 219.71 86.96 207.89 110.67 211.9 109.53 221.43"
                        />
                        <polygon
                            id="section_a_29"
                            className="cls-3"
                            points="111.36 209.43 87.81 204.56 90.77 193.04 113.75 200.13 111.36 209.43"
                        />
                        <polygon
                            id="section_a_30"
                            className="cls-3"
                            points="114.72 197.81 91.96 190.07 96.32 179 118.24 188.88 114.72 197.81"
                        />
                        <polygon
                            id="section_a_31"
                            className="cls-3"
                            points="119.51 186.61 97.78 176.33 103.38 165.83 124.03 178.14 119.51 186.61"
                        />
                        <polygon
                            id="section_a_32"
                            className="cls-3"
                            points="125.61 175.87 105.06 163.4 111.72 153.53 130.98 167.91 125.61 175.87"
                        />
                        <polygon
                            id="section_a_33"
                            className="cls-3"
                            points="132.88 165.66 113.61 151.31 121.17 142.1 138.98 158.24 132.88 165.66"
                        />
                        <polygon
                            id="section_a_34"
                            className="cls-3"
                            points="141 156.21 123.08 140.19 131.43 131.7 147.73 149.36 141 156.21"
                        />
                        <polygon
                            id="section_a_35"
                            className="cls-3"
                            points="149.42 148 133.1 130.37 142.23 122.7 156.77 141.83 149.42 148"
                        />
                        <polygon
                            id="section_a_36"
                            className="cls-3"
                            points="158.79 140.47 144.1 121.45 153.87 114.63 166.66 134.98 158.79 140.47"
                        />
                        <polygon
                            id="section_a_37"
                            className="cls-3"
                            points="169.02 133.64 155.96 113.47 166.27 107.49 177.31 128.82 169.02 133.64"
                        />
                        <polygon
                            id="section_a_38"
                            className="cls-3"
                            points="180 127.56 168.57 106.43 179.31 101.27 188.65 123.4 180 127.56"
                        />
                        <polygon
                            id="section_a_39"
                            className="cls-3"
                            points="191.65 122.25 181.82 100.33 192.93 95.99 200.58 118.76 191.65 122.25"
                        />
                        <polygon
                            id="section_a_40"
                            className="cls-3"
                            points="203.85 117.76 195.62 95.19 207.01 91.66 213.01 114.91 203.85 117.76"
                        />
                        <polygon
                            id="section_a_41"
                            className="cls-3"
                            points="216.51 114.1 209.87 91.02 221.48 88.29 225.85 111.9 216.51 114.1"
                        />
                        <polygon
                            id="section_a_42"
                            className="cls-3"
                            points="229.52 111.31 224.47 87.83 236.25 85.9 238.98 109.75 229.52 111.31"
                        />
                        <polygon
                            id="section_a_43"
                            className="cls-3"
                            points="242.78 109.4 239.34 85.64 251.22 84.51 252.33 108.49 242.78 109.4"
                        />
                        <polygon
                            id="section_a_44"
                            className="cls-3"
                            points="255.92 108.4 254.11 84.46 266.04 84.14 265.5 108.14 255.92 108.4"
                        />
                        <polygon
                            id="section_a_45"
                            className="cls-3"
                            points="268.63 108.36 268.44 84.36 280.37 84.85 278.21 108.76 268.63 108.36"
                        />
                        <polygon
                            id="section_a_46"
                            className="cls-3"
                            points="281.6 109.24 283.03 85.28 294.9 86.57 291.13 110.28 281.6 109.24"
                        />
                        <polygon
                            id="section_a_47"
                            className="cls-3"
                            points="294.24 110.94 297.22 87.12 308.98 89.19 303.68 112.59 294.24 110.94"
                        />
                        <polygon
                            id="section_a_48"
                            className="cls-3"
                            points="306.56 113.41 310.97 89.82 322.59 92.59 315.88 115.63 306.56 113.41"
                        />
                        <polygon
                            id="section_a_49"
                            className="cls-3"
                            points="319.15 116.73 325.1 93.48 336.51 97.01 328.31 119.56 319.15 116.73"
                        />
                        <polygon
                            id="section_a_50"
                            className="cls-3"
                            points="331.45 120.9 339.33 98.24 350.41 102.72 340.34 124.49 331.45 120.9"
                        />
                        <polygon
                            id="section_a_51"
                            className="cls-3"
                            points="342.8 125.86 352.69 104 363.32 109.46 351.33 130.23 342.8 125.86"
                        />
                        <polygon
                            id="section_a_52"
                            className="cls-3"
                            points="353.55 131.75 365.34 110.86 375.45 117.25 361.65 136.87 353.55 131.75"
                        />
                        <polygon
                            id="section_a_53"
                            className="cls-3"
                            points="364.02 138.78 377.46 118.91 387.02 126.09 371.68 144.53 364.02 138.78"
                        />
                    </g>
                    <g id="sections_b">
                        <polygon
                            id="section_b_1"
                            className="cls-4"
                            points="413.39 334.23 424.3 324.52 412.48 313.21 402.5 322.1 413.39 334.23"
                        />
                        <polygon
                            id="section_b_2"
                            data-name="section_b_"
                            className="cls-4"
                            points="399.77 345.1 411.4 336.27 400.52 324.07 389.89 332.14 399.77 345.1"
                        />
                        <polygon
                            id="section_b_3"
                            data-name="section_b_"
                            className="cls-4"
                            points="385.31 354.92 397.57 347.01 387.68 334.02 376.47 341.25 385.31 354.92"
                        />
                        <polygon
                            id="section_b_4"
                            data-name="section_b_"
                            className="cls-4"
                            points="370.13 363.67 382.94 356.69 374.06 343 362.36 349.38 370.13 363.67"
                        />
                        <polygon
                            id="section_b_5"
                            data-name="section_b_"
                            className="cls-4"
                            points="354.34 371.29 367.61 365.26 359.79 350.96 347.66 356.48 354.34 371.29"
                        />
                        <polygon
                            id="section_b_6"
                            data-name="section_b_"
                            className="cls-4"
                            points="338.05 377.74 351.71 372.69 344.98 357.86 332.48 362.48 338.05 377.74"
                        />
                        <polygon
                            id="section_b_7"
                            data-name="section_b_"
                            className="cls-4"
                            points="321.35 382.96 335.34 378.93 329.75 363.65 316.95 367.33 321.35 382.96"
                        />
                        <polygon
                            id="section_b_8"
                            data-name="section_b_"
                            className="cls-4"
                            points="304.38 386.9 318.62 383.93 314.21 368.29 301.18 371 304.38 386.9"
                        />
                        <polygon
                            id="section_b_9"
                            data-name="section_b_"
                            className="cls-4"
                            points="287.22 389.51 301.64 387.65 298.47 371.72 285.29 373.42 287.22 389.51"
                        />
                        <polygon
                            id="section_b_10"
                            data-name="section_b_"
                            className="cls-4"
                            points="270.01 390.74 284.52 390.03 282.66 373.91 269.39 374.56 270.01 390.74"
                        />
                        <polygon
                            id="section_b_11"
                            data-name="section_b_"
                            className="cls-4"
                            points="252.91 390.52 267.43 390.99 266.89 374.78 253.62 374.36 252.91 390.52"
                        />
                        <polygon
                            id="section_b_12"
                            data-name="section_b_"
                            className="cls-4"
                            points="235.17 388.98 249.61 390.5 250.29 374.32 237.08 372.93 235.17 388.98"
                        />
                        <polygon
                            id="section_b_13"
                            data-name="section_b_"
                            className="cls-4"
                            points="217.67 386.16 231.95 388.74 233.84 372.66 220.78 370.31 217.67 386.16"
                        />
                        <polygon
                            id="section_b_14"
                            data-name="section_b_"
                            className="cls-4"
                            points="200.75 382.1 214.79 385.73 217.89 369.86 205.06 366.54 200.75 382.1"
                        />
                        <polygon
                            id="section_b_15"
                            data-name="section_b_"
                            className="cls-4"
                            points="184.47 376.85 198.19 381.53 202.51 365.96 189.96 361.68 184.47 376.85"
                        />
                        <polygon
                            id="section_b_16"
                            data-name="section_b_"
                            className="cls-4"
                            points="168.91 370.45 182.22 376.18 187.75 361.01 175.58 355.77 168.91 370.45"
                        />
                        <polygon
                            id="section_b_17"
                            data-name="section_b_"
                            className="cls-4"
                            points="152.83 362.24 165.6 369.06 172.4 354.43 160.72 348.2 152.83 362.24"
                        />
                        <polygon
                            id="section_b_18"
                            data-name="section_b_"
                            className="cls-4"
                            points="137.06 352.37 149.17 360.3 157.26 346.36 146.19 339.11 137.06 352.37"
                        />
                        <polygon
                            id="section_b_19"
                            data-name="section_b_"
                            className="cls-4"
                            points="122.54 341.26 133.85 350.27 143.21 337.18 132.87 328.94 122.54 341.26"
                        />
                        <polygon
                            id="section_b_20"
                            data-name="section_b_"
                            className="cls-4"
                            points="109.46 329.09 119.84 339.15 130.44 327.05 120.95 317.85 109.46 329.09"
                        />
                        <polygon
                            id="section_b_21"
                            data-name="section_b_"
                            className="cls-4"
                            points="97.83 315.79 107.13 326.85 118.91 315.92 110.41 305.81 97.83 315.79"
                        />
                        <polygon
                            id="section_b_22"
                            data-name="section_b_"
                            className="cls-4"
                            points="87.85 301.54 95.91 313.53 108.79 303.95 101.42 292.99 87.85 301.54"
                        />
                        <polygon
                            id="section_b_23"
                            data-name="section_b_"
                            className="cls-4"
                            points="79.61 286.42 86.27 299.23 100.15 291.18 94.07 279.48 79.61 286.42"
                        />
                        <polygon
                            id="section_b_24"
                            data-name="section_b_"
                            className="cls-4"
                            points="73.25 270.51 78.34 284.01 93.08 277.69 88.42 265.35 73.25 270.51"
                        />
                        <polygon
                            id="section_b_25"
                            data-name="section_b_"
                            className="cls-4"
                            points="68.88 253.94 72.28 267.95 87.67 263.51 84.56 250.7 68.88 253.94"
                        />
                        <polygon
                            id="section_b_26"
                            data-name="section_b_"
                            className="cls-4"
                            points="66.6 236.81 68.21 251.13 84.03 248.69 82.55 235.6 66.6 236.81"
                        />
                        <polygon
                            id="section_b_27"
                            data-name="section_b_"
                            className="cls-4"
                            points="66.53 219.67 66.3 234.07 82.28 233.69 82.49 220.53 66.53 219.67"
                        />
                        <polygon
                            id="section_b_28"
                            data-name="section_b_"
                            className="cls-4"
                            points="68.77 202.39 66.71 216.63 82.59 218.32 84.48 205.3 68.77 202.39"
                        />
                        <polygon
                            id="section_b_29"
                            data-name="section_b_"
                            className="cls-4"
                            points="73.17 185.6 69.32 199.46 84.84 203.17 88.36 190.5 73.17 185.6"
                        />
                        <polygon
                            id="section_b_30"
                            data-name="section_b_"
                            className="cls-4"
                            points="79.56 169.6 74.04 182.88 88.97 188.48 94.01 176.35 79.56 169.6"
                        />
                        <polygon
                            id="section_b_31"
                            data-name="section_b_"
                            className="cls-4"
                            points="87.8 154.47 80.76 167 94.9 174.34 101.33 162.89 87.8 154.47"
                        />
                        <polygon
                            id="section_b_32"
                            data-name="section_b_"
                            className="cls-4"
                            points="97.8 140.23 89.4 151.89 102.6 160.78 110.27 150.13 97.8 140.23"
                        />
                        <polygon
                            id="section_b_33"
                            data-name="section_b_"
                            className="cls-4"
                            points="109.28 127.08 99.69 137.76 111.84 148.03 120.61 138.26 109.28 127.08"
                        />
                        <polygon
                            id="section_b_34"
                            data-name="section_b_"
                            className="cls-4"
                            points="122.28 114.94 111.67 124.59 122.69 136.04 132.39 127.22 122.28 114.94"
                        />
                        <polygon
                            id="section_b_35"
                            data-name="section_b_"
                            className="cls-4"
                            points="136.62 103.92 125.13 112.51 134.97 124.97 145.47 117.12 136.62 103.92"
                        />
                        <polygon
                            id="section_b_36"
                            data-name="section_b_"
                            className="cls-4"
                            points="152.19 94.09 139.97 101.58 148.59 114.89 159.76 108.05 152.19 94.09"
                        />
                        <polygon
                            id="section_b_37"
                            data-name="section_b_"
                            className="cls-4"
                            points="168.34 85.78 155.52 92.18 162.93 106.19 174.64 100.35 168.34 85.78"
                        />
                        <polygon
                            id="section_b_38"
                            data-name="section_b_"
                            className="cls-4"
                            points="183.75 79.4 170.48 84.77 176.73 99.32 188.86 94.42 183.75 79.4"
                        />
                        <polygon
                            id="section_b_39"
                            data-name="section_b_"
                            className="cls-4"
                            points="199.87 74.14 186.23 78.46 191.31 93.45 203.77 89.5 199.87 74.14"
                        />
                        <polygon
                            id="section_b_40"
                            data-name="section_b_"
                            className="cls-4"
                            points="216.63 70.05 202.71 73.32 206.61 88.64 219.33 85.65 216.63 70.05"
                        />
                        <polygon
                            id="section_b_41"
                            data-name="section_b_"
                            className="cls-4"
                            points="233.95 67.17 219.83 69.39 222.54 84.94 235.45 82.92 233.95 67.17"
                        />
                        <polygon
                            id="section_b_42"
                            data-name="section_b_"
                            className="cls-4"
                            points="251.76 65.55 237.53 66.71 239.05 82.42 252.06 81.35 251.76 65.55"
                        />
                        <polygon
                            id="section_b_43"
                            data-name="section_b_"
                            className="cls-4"
                            points="269.56 65.23 255.28 65.34 255.62 81.11 268.66 81 269.56 65.23"
                        />
                        <polygon
                            id="section_b_44"
                            data-name="section_b_"
                            className="cls-4"
                            points="286.96 66.35 272.73 65.36 271.82 81.08 284.83 81.99 286.96 66.35"
                        />
                        <polygon
                            id="section_b_45"
                            data-name="section_b_"
                            className="cls-4"
                            points="304.3 68.82 290.19 66.74 288.05 82.33 300.95 84.23 304.3 68.82"
                        />
                        <polygon
                            id="section_b_46"
                            data-name="section_b_"
                            className="cls-4"
                            points="321.4 72.59 307.49 69.45 304.16 84.82 316.87 87.69 321.4 72.59"
                        />
                        <polygon
                            id="section_b_47"
                            data-name="section_b_"
                            className="cls-4"
                            points="338.15 77.65 324.53 73.47 320.03 88.53 332.48 92.34 338.15 77.65"
                        />
                        <polygon
                            id="section_b_48"
                            data-name="section_b_"
                            className="cls-4"
                            points="354.46 83.95 341.2 78.76 335.56 93.41 347.67 98.15 354.46 83.95"
                        />
                        <polygon
                            id="section_b_49"
                            data-name="section_b_"
                            className="cls-4"
                            points="370.21 91.47 357.39 85.29 350.63 99.44 362.34 105.09 370.21 91.47"
                        />
                        <polygon
                            id="section_b_50"
                            data-name="section_b_"
                            className="cls-4"
                            points="385.28 100.18 372.99 93.02 365.13 106.58 376.36 113.12 385.28 100.18"
                        />
                        <polygon
                            id="section_b_51"
                            data-name="section_b_"
                            className="cls-4"
                            points="399.62 110.1 387.95 101.98 379.01 114.84 389.67 122.25 399.62 110.1"
                        />
                        <polygon
                            id="section_b_52"
                            data-name="section_b_"
                            className="cls-4"
                            points="412.97 121.06 402.03 112.01 392.04 124.05 402.04 132.32 412.97 121.06"
                        />
                        <polygon
                            id="section_b_53"
                            data-name="section_b_"
                            className="cls-4"
                            points="425.31 133.13 415.2 123.17 404.19 134.27 413.44 143.37 425.31 133.13"
                        />
                    </g>
                    <g id="sections_c">
                        <polygon
                            id="section_c_1"
                            className="cls-5"
                            points="428.25 367.35 440.98 355.81 427.49 342.02 415.85 352.57 428.25 367.35"
                        />
                        <polygon
                            id="section_c_2"
                            className="cls-5"
                            points="412.53 379.29 426.23 368.93 414.02 354.01 401.5 363.48 412.53 379.29"
                        />
                        <polygon
                            id="section_c_3"
                            className="cls-5"
                            points="395.81 389.89 410.33 380.74 399.45 364.85 386.17 373.22 395.81 389.89"
                        />
                        <polygon
                            id="section_c_4"
                            className="cls-5"
                            points="378.23 399.14 393.43 391.2 383.89 374.49 369.98 381.75 378.23 399.14"
                        />
                        <polygon
                            id="section_c_5"
                            className="cls-5"
                            points="359.88 407.02 375.66 400.31 367.47 382.91 353.04 389.05 359.88 407.02"
                        />
                        <polygon
                            id="section_c_6"
                            className="cls-5"
                            points="340.91 413.51 357.15 408.03 350.31 390.08 335.47 395.09 340.91 413.51"
                        />
                        <polygon
                            id="section_c_7"
                            className="cls-5"
                            points="321.43 418.61 338.02 414.36 332.55 395.96 317.37 399.85 321.43 418.61"
                        />
                        <polygon
                            id="section_c_8"
                            className="cls-5"
                            points="301.55 422.28 318.4 419.27 314.3 400.53 298.89 403.29 301.55 422.28"
                        />
                        <polygon
                            id="section_c_9"
                            className="cls-5"
                            points="281.38 424.51 298.4 422.74 295.69 403.76 280.12 405.39 281.38 424.51"
                        />
                        <polygon
                            id="section_c_10"
                            className="cls-5"
                            points="261.14 425.27 278.23 424.75 276.91 405.64 261.28 406.12 261.14 425.27"
                        />
                        <polygon
                            id="section_c_11"
                            className="cls-5"
                            points="241.09 424.39 258.16 425.25 258.39 406.12 242.78 405.33 241.09 424.39"
                        />
                        <polygon
                            id="section_c_12"
                            className="cls-5"
                            points="220.59 421.76 237.52 424.04 239.34 405 223.86 402.92 220.59 421.76"
                        />
                        <polygon
                            id="section_c_13"
                            className="cls-5"
                            points="200.73 417.56 217.42 421.18 220.75 402.38 205.5 399.06 200.73 417.56"
                        />
                        <polygon
                            id="section_c_14"
                            className="cls-5"
                            points="181.59 411.94 197.94 416.83 202.71 398.35 187.76 393.88 181.59 411.94"
                        />
                        <polygon
                            id="section_c_15"
                            className="cls-5"
                            points="163.24 405.04 179.18 411.12 185.29 393.06 170.72 387.5 163.24 405.04"
                        />
                        <polygon
                            id="section_c_16"
                            className="cls-5"
                            points="145.76 396.99 161.21 404.19 168.59 386.63 154.46 380.04 145.76 396.99"
                        />
                        <polygon
                            id="section_c_17"
                            className="cls-5"
                            points="127.37 386.66 142.13 395.16 150.99 378.3 137.49 370.53 127.37 386.66"
                        />
                        <polygon
                            id="section_c_18"
                            className="cls-5"
                            points="110.06 374.79 124 384.57 134.32 368.59 121.58 359.65 110.06 374.79"
                        />
                        <polygon
                            id="section_c_19"
                            className="cls-5"
                            points="94.16 361.57 107.12 372.6 118.87 357.65 107.01 347.57 94.16 361.57"
                        />
                        <polygon
                            id="section_c_20"
                            className="cls-5"
                            points="79.83 347.16 91.68 359.37 104.79 345.63 93.96 334.46 79.83 347.16"
                        />
                        <polygon
                            id="section_c_21"
                            className="cls-5"
                            points="67.08 331.48 77.65 344.81 92.05 332.45 82.39 320.27 67.08 331.48"
                        />
                        <polygon
                            id="section_c_22"
                            className="cls-5"
                            points="56.15 314.78 65.27 329.12 80.86 318.34 72.53 305.23 56.15 314.78"
                        />
                        <polygon
                            id="section_c_23"
                            className="cls-5"
                            points="47.13 297.12 54.64 312.35 71.3 303.32 64.42 289.39 47.13 297.12"
                        />
                        <polygon
                            id="section_c_24"
                            className="cls-5"
                            points="40.13 278.59 45.89 294.56 63.44 287.46 58.17 272.86 40.13 278.59"
                        />
                        <polygon
                            id="section_c_25"
                            className="cls-5"
                            points="35.29 259.32 39.16 275.84 57.4 270.82 53.85 255.72 35.29 259.32"
                        />
                        <polygon
                            id="section_c_26"
                            className="cls-5"
                            points="32.7 239.43 34.6 256.29 53.29 253.45 51.55 238.04 32.7 239.43"
                        />
                        <polygon
                            id="section_c_27"
                            className="cls-5"
                            points="32.48 219.47 32.36 236.43 51.23 235.83 51.34 220.34 32.48 219.47"
                        />
                        <polygon
                            id="section_c_28"
                            className="cls-5"
                            points="34.77 199.41 32.63 216.22 51.42 217.89 53.38 202.52 34.77 199.41"
                        />
                        <polygon
                            id="section_c_29"
                            className="cls-5"
                            points="39.39 179.81 35.27 196.24 53.72 200.11 57.48 185.1 39.39 179.81"
                        />
                        <polygon
                            id="section_c_30"
                            className="cls-5"
                            points="46.19 161.06 40.2 176.89 58.05 182.88 63.53 168.41 46.19 161.06"
                        />
                        <polygon
                            id="section_c_31"
                            className="cls-5"
                            points="55.03 143.26 47.29 158.3 64.34 166.25 71.42 152.5 55.03 143.26"
                        />
                        <polygon
                            id="section_c_32"
                            className="cls-5"
                            points="65.75 126.48 56.43 140.59 72.51 150.33 81.03 137.44 65.75 126.48"
                        />
                        <polygon
                            id="section_c_33"
                            className="cls-5"
                            points="78.24 110.78 67.51 123.84 82.48 135.19 92.28 123.26 78.24 110.78"
                        />
                        <polygon
                            id="section_c_34"
                            className="cls-5"
                            points="92.28 96.32 80.31 108.24 94.06 121.02 105 110.13 92.28 96.32"
                        />
                        <polygon
                            id="section_c_35"
                            className="cls-5"
                            points="107.91 83.03 94.86 93.74 107.31 107.76 119.24 97.97 107.91 83.03"
                        />
                        <polygon
                            id="section_c_36"
                            className="cls-5"
                            points="124.89 71.08 110.91 80.53 122 95.63 134.78 86.99 124.89 71.08"
                        />
                        <polygon
                            id="section_c_37"
                            className="cls-5"
                            points="143.13 60.51 128.37 68.67 138.06 84.69 151.55 77.23 143.13 60.51"
                        />
                        <polygon
                            id="section_c_38"
                            className="cls-5"
                            points="160.62 52.16 145.3 59.17 153.74 75.86 167.74 69.45 160.62 52.16"
                        />
                        <polygon
                            id="section_c_39"
                            className="cls-5"
                            points="178.7 45.12 162.9 50.96 170.06 68.22 184.5 62.88 178.7 45.12"
                        />
                        <polygon
                            id="section_c_40"
                            className="cls-5"
                            points="197.57 39.36 181.38 43.98 187.19 61.72 201.99 57.51 197.57 39.36"
                        />
                        <polygon
                            id="section_c_41"
                            className="cls-5"
                            points="217.14 35.01 200.65 38.34 205.04 56.47 220.12 53.44 217.14 35.01"
                        />
                        <polygon
                            id="section_c_42"
                            className="cls-5"
                            points="237.34 32.19 220.63 34.17 223.55 52.58 238.81 50.77 237.34 32.19"
                        />
                        <polygon
                            id="section_c_43"
                            className="cls-5"
                            points="258.03 31 241.23 31.59 242.6 50.16 257.96 49.63 258.03 31"
                        />
                        <polygon
                            id="section_c_44"
                            className="cls-5"
                            points="278.06 31.53 261.28 30.76 261.15 49.37 276.49 50.07 278.06 31.53"
                        />
                        <polygon
                            id="section_c_45"
                            className="cls-5"
                            points="298.27 33.57 281.6 31.57 280.1 50.11 295.34 51.94 298.27 33.57"
                        />
                        <polygon
                            id="section_c_46"
                            className="cls-5"
                            points="318.33 37.07 301.86 33.87 299.02 52.23 314.08 55.16 318.33 37.07"
                        />
                        <polygon
                            id="section_c_47"
                            className="cls-5"
                            points="338.08 42.01 321.89 37.62 317.74 55.71 332.53 59.72 338.08 42.01"
                        />
                        <polygon
                            id="section_c_48"
                            className="cls-5"
                            points="357.36 48.35 341.55 42.78 336.07 60.51 350.52 65.6 357.36 48.35"
                        />
                        <polygon
                            id="section_c_49"
                            className="cls-5"
                            points="375.99 56.09 360.65 49.34 353.85 66.58 367.87 72.75 375.99 56.09"
                        />
                        <polygon
                            id="section_c_50"
                            className="cls-5"
                            points="393.8 65.2 379.06 57.25 370.92 73.88 384.39 81.14 393.8 65.2"
                        />
                        <polygon
                            id="section_c_51"
                            className="cls-5"
                            points="410.62 75.66 396.61 66.5 387.09 82.37 399.9 90.74 410.62 75.66"
                        />
                        <polygon
                            id="section_c_52"
                            className="cls-5"
                            points="426.28 87.48 413.16 77.1 402.26 92.03 414.25 101.52 426.28 87.48"
                        />
                        <polygon
                            id="section_c_53"
                            className="cls-5"
                            points="440.49 100.53 428.45 88.92 416.16 102.71 427.16 113.32 440.49 100.53"
                        />
                    </g>
                    <g id="sections_d">
                        <polygon
                            id="section_d_1"
                            className="cls-6"
                            points="442.12 388.05 455.73 375.88 440.76 360.24 428.23 371.44 442.12 388.05"
                        />
                        <polygon
                            id="section_d_2"
                            data-name="section_d_"
                            className="cls-6"
                            points="424.95 401.01 439.57 390.06 426.02 373.17 412.56 383.25 424.95 401.01"
                        />
                        <polygon
                            id="section_d_3"
                            data-name="section_d_"
                            className="cls-6"
                            points="406.77 412.43 422.28 402.77 410.22 384.79 395.95 393.68 406.77 412.43"
                        />
                        <polygon
                            id="section_d_4"
                            data-name="section_d_"
                            className="cls-6"
                            points="387.65 422.33 403.93 413.99 393.42 395.07 378.44 402.73 387.65 422.33"
                        />
                        <polygon
                            id="section_d_5"
                            data-name="section_d_"
                            className="cls-6"
                            points="367.67 430.69 384.58 423.71 375.65 403.99 360.09 410.41 367.67 430.69"
                        />
                        <polygon
                            id="section_d_6"
                            data-name="section_d_"
                            className="cls-6"
                            points="346.89 437.52 364.31 431.9 356.97 411.53 340.95 416.7 346.89 437.52"
                        />
                        <polygon
                            id="section_d_7"
                            data-name="section_d_"
                            className="cls-6"
                            points="325.39 442.82 343.2 438.54 337.44 417.68 321.06 421.6 325.39 442.82"
                        />
                        <polygon
                            id="section_d_8"
                            data-name="section_d_"
                            className="cls-6"
                            points="303.23 446.58 321.31 443.64 317.1 422.4 300.47 425.11 303.23 446.58"
                        />
                        <polygon
                            id="section_d_9"
                            data-name="section_d_"
                            className="cls-6"
                            points="280.46 448.82 298.72 447.17 296.02 425.69 279.24 427.21 280.46 448.82"
                        />
                        <polygon
                            id="section_d_10"
                            data-name="section_d_"
                            className="cls-6"
                            points="257.28 449.53 275.62 449.12 274.39 427.51 257.53 427.89 257.28 449.53"
                        />
                        <polygon
                            id="section_d_11"
                            data-name="section_d_"
                            className="cls-6"
                            points="233.87 448.32 252.19 449.44 252.76 427.8 235.93 426.77 233.87 448.32"
                        />
                        <polygon
                            id="section_d_12"
                            data-name="section_d_"
                            className="cls-6"
                            points="210.33 445.1 228.5 447.75 230.89 426.24 214.2 423.79 210.33 445.1"
                        />
                        <polygon
                            id="section_d_13"
                            data-name="section_d_"
                            className="cls-6"
                            points="187.47 439.99 205.36 444.16 209.54 422.91 193.1 419.08 187.47 439.99"
                        />
                        <polygon
                            id="section_d_14"
                            data-name="section_d_"
                            className="cls-6"
                            points="165.39 433.08 182.88 438.73 188.82 417.91 172.75 412.72 165.39 433.08"
                        />
                        <polygon
                            id="section_d_15"
                            data-name="section_d_"
                            className="cls-6"
                            points="144.23 424.46 161.2 431.55 168.85 411.3 153.27 404.79 144.23 424.46"
                        />
                        <polygon
                            id="section_d_16"
                            data-name="section_d_"
                            className="cls-6"
                            points="124.11 414.21 140.42 422.72 149.75 403.18 134.77 395.37 124.11 414.21"
                        />
                        <polygon
                            id="section_d_17"
                            data-name="section_d_"
                            className="cls-6"
                            points="105.14 402.43 120.67 412.3 131.64 393.63 117.37 384.56 105.14 402.43"
                        />
                        <polygon
                            id="section_d_18"
                            data-name="section_d_"
                            className="cls-6"
                            points="87.41 389.15 102.04 400.34 114.58 382.69 101.15 372.42 87.41 389.15"
                        />
                        <polygon
                            id="section_d_19"
                            data-name="section_d_"
                            className="cls-6"
                            points="71.17 374.59 84.75 387.04 98.82 370.58 86.35 359.15 71.17 374.59"
                        />
                        <polygon
                            id="section_d_20"
                            data-name="section_d_"
                            className="cls-6"
                            points="56.41 358.67 68.8 372.33 84.31 357.23 72.95 344.7 56.41 358.67"
                        />
                        <polygon
                            id="section_d_21"
                            data-name="section_d_"
                            className="cls-6"
                            points="43.33 341.57 54.36 356.36 71.25 342.81 61.12 329.24 43.33 341.57"
                        />
                        <polygon
                            id="section_d_22"
                            data-name="section_d_"
                            className="cls-6"
                            points="32.05 323.37 41.57 339.18 59.71 327.36 50.97 312.86 32.05 323.37"
                        />
                        <polygon
                            id="section_d_23"
                            data-name="section_d_"
                            className="cls-6"
                            points="22.7 304.17 30.55 320.87 49.81 310.98 42.6 295.64 22.7 304.17"
                        />
                        <polygon
                            id="section_d_24"
                            data-name="section_d_"
                            className="cls-6"
                            points="15.41 284.06 21.45 301.51 41.65 293.71 36.1 277.7 15.41 284.06"
                        />
                        <polygon
                            id="section_d_25"
                            data-name="section_d_"
                            className="cls-6"
                            points="10.31 263.16 14.41 281.18 35.34 275.63 31.57 259.1 10.31 263.16"
                        />
                        <polygon
                            id="section_d_26"
                            data-name="section_d_"
                            className="cls-6"
                            points="7.52 241.6 9.59 259.97 31 256.79 29.11 239.94 7.52 241.6"
                        />
                        <polygon
                            id="section_d_27"
                            data-name="section_d_"
                            className="cls-6"
                            points="7.15 219.88 7.13 238.38 28.77 237.63 28.79 220.67 7.15 219.88"
                        />
                        <polygon
                            id="section_d_28"
                            data-name="section_d_"
                            className="cls-6"
                            points="9.4 197.76 7.26 216.14 28.84 217.87 30.8 201.01 9.4 197.76"
                        />
                        <polygon
                            id="section_d_29"
                            data-name="section_d_"
                            className="cls-6"
                            points="14.11 176.11 9.9 194.14 31.14 198.31 35 181.78 14.11 176.11"
                        />
                        <polygon
                            id="section_d_30"
                            data-name="section_d_"
                            className="cls-6"
                            points="21.11 155.43 14.91 172.89 35.56 179.4 41.24 163.4 21.11 155.43"
                        />
                        <polygon
                            id="section_d_31"
                            data-name="section_d_"
                            className="cls-6"
                            points="30.23 135.81 22.17 152.5 41.99 161.21 49.38 145.91 30.23 135.81"
                        />
                        <polygon
                            id="section_d_32"
                            data-name="section_d_"
                            className="cls-6"
                            points="41.31 117.32 31.54 133.08 50.35 143.82 59.3 129.37 41.31 117.32"
                        />
                        <polygon
                            id="section_d_33"
                            data-name="section_d_"
                            className="cls-6"
                            points="54.22 99.99 42.92 114.7 60.55 127.26 70.91 113.78 54.22 99.99"
                        />
                        <polygon
                            id="section_d_34"
                            data-name="section_d_"
                            className="cls-6"
                            points="68.67 83.99 56 97.56 72.35 111.76 83.96 99.33 68.67 83.99"
                        />
                        <polygon
                            id="section_d_35"
                            data-name="section_d_"
                            className="cls-6"
                            points="84.7 69.25 70.82 81.6 85.79 97.24 98.5 85.93 84.7 69.25"
                        />
                        <polygon
                            id="section_d_36"
                            data-name="section_d_"
                            className="cls-6"
                            points="102.07 55.9 87.14 66.97 100.66 83.89 114.33 73.75 102.07 55.9"
                        />
                        <polygon
                            id="section_d_37"
                            data-name="section_d_"
                            className="cls-6"
                            points="120.66 43.99 104.83 53.73 116.83 71.75 131.34 62.83 120.66 43.99"
                        />
                        <polygon
                            id="section_d_38"
                            data-name="section_d_"
                            className="cls-6"
                            points="140.35 33.59 123.75 41.97 134.19 60.93 149.4 53.26 140.35 33.59"
                        />
                        <polygon
                            id="section_d_39"
                            data-name="section_d_"
                            className="cls-6"
                            points="161 24.76 143.75 31.74 152.59 51.5 168.39 45.11 161 24.76"
                        />
                        <polygon
                            id="section_d_40"
                            data-name="section_d_"
                            className="cls-6"
                            points="182.48 17.57 164.71 23.11 171.9 43.54 188.17 38.46 182.48 17.57"
                        />
                        <polygon
                            id="section_d_41"
                            data-name="section_d_"
                            className="cls-6"
                            points="204.65 12.1 186.48 16.18 191.98 37.12 208.62 33.39 204.65 12.1"
                        />
                        <polygon
                            id="section_d_42"
                            data-name="section_d_"
                            className="cls-6"
                            points="227.4 8.43 208.94 10.99 212.7 32.31 229.59 29.97 227.4 8.43"
                        />
                        <polygon
                            id="section_d_43"
                            data-name="section_d_"
                            className="cls-6"
                            points="250.57 6.62 231.95 7.64 233.93 29.2 250.96 28.27 250.57 6.62"
                        />
                        <polygon
                            id="section_d_44"
                            data-name="section_d_"
                            className="cls-6"
                            points="273.31 6.78 254.67 6.22 254.81 27.87 271.88 28.38 273.31 6.78"
                        />
                        <polygon
                            id="section_d_45"
                            data-name="section_d_"
                            className="cls-6"
                            points="296.58 8.58 278.01 6.77 276.7 28.38 293.7 30.04 296.58 8.58"
                        />
                        <polygon
                            id="section_d_46"
                            data-name="section_d_"
                            className="cls-6"
                            points="319.25 11.93 300.84 8.82 298.02 30.29 314.87 33.13 319.25 11.93"
                        />
                        <polygon
                            id="section_d_47"
                            data-name="section_d_"
                            className="cls-6"
                            points="341.18 16.83 323.03 12.38 318.65 33.59 335.25 37.66 341.18 16.83"
                        />
                        <polygon
                            id="section_d_48"
                            data-name="section_d_"
                            className="cls-6"
                            points="362.31 23.28 344.56 17.46 338.57 38.27 354.82 43.6 362.31 23.28"
                        />
                        <polygon
                            id="section_d_49"
                            data-name="section_d_"
                            className="cls-6"
                            points="382.61 31.27 365.36 24.07 357.75 44.34 373.54 50.93 382.61 31.27"
                        />
                        <polygon
                            id="section_d_50"
                            data-name="section_d_"
                            className="cls-6"
                            points="402.01 40.79 385.39 32.2 376.17 51.79 391.37 59.65 402.01 40.79"
                        />
                        <polygon
                            id="section_d_51"
                            data-name="section_d_"
                            className="cls-6"
                            points="420.46 51.83 404.6 41.88 393.79 60.64 408.28 69.73 420.46 51.83"
                        />
                        <polygon
                            id="section_d_52"
                            data-name="section_d_"
                            className="cls-6"
                            points="437.9 64.35 422.93 53.1 410.58 70.88 424.27 81.17 437.9 64.35"
                        />
                        <polygon
                            id="section_d_53"
                            data-name="section_d_"
                            className="cls-6"
                            points="454.3 78.34 440.33 65.86 426.51 82.54 439.29 93.94 454.3 78.34"
                        />
                    </g>
                </g>
            </svg>
        </StadeContent>
    );
};
