import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

import { DoubleStacked, ContentButtonLeft, ContentButtonRight, ContentNumber } from './style';

type Props = {
    onUpPress: () => void;
    onDownPress: () => void;
    number: number;
};

// TODO make it so that they are always vertically centered on their parent
export const MoreLessArrows: React.FC<Props> = (props: Props) => {
    const { onUpPress, onDownPress, number } = props;

    return (
        <DoubleStacked>
            <ContentButtonLeft number={number} onClick={onDownPress}>
                <FontAwesomeIcon icon={faMinus} />
            </ContentButtonLeft>
            <ContentNumber number={number}>{number}</ContentNumber>
            <ContentButtonRight number={number} onClick={onUpPress}>
                <FontAwesomeIcon icon={faPlus} />
            </ContentButtonRight>
        </DoubleStacked>
    );
};
