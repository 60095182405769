import styled from 'styled-components';
import Img from 'gatsby-image';

export const Page = styled.div`
    height: 95vh;
    padding: 0px 80px;
    display: flex;
    flex-direction: column;

    justify-content: space-evenly;

    @media (max-height: 820px, max-width: 768px) {
        min-height: 125vh;
        padding: 40px 20px;
    }

    @media (max-width: 768px) {
        justify-content: center;
    }

    @media (max-width: 450px) {
        padding: 40px 20px;
        min-height: 120vh;
    }

    @media (max-width: 360px) {
        padding: 40px 20px;
        min-height: 120vh;
    }

    @media (max-height: 850px) {
        min-height: 110vh;
    }

    @media (max-height: 736px) {
        min-height: 145vh;
    }
`;

export const Logo = styled(Img)`
    width: 100%;
    margin: auto;

    @media (max-height: 850px) {
        margin: 0px auto;
    }

    @media (max-width: 1024px) {
        width: 100%;
    }

    @media (max-width: 768px) {
        margin: 0px auto;
        margin-top: 20px;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    justify-content: space-between;
    width: 100%;
    height: 90%;

    @media (max-width: 768px) {
        flex-direction: column-reverse;
        height: 100%;
    }
`;

export const ContainerText = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 5;

    justify-content: space-evenly;
    width: 50%;

    @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
    }
`;

export const ContainerSeat = styled.div`
    width: 500px;
    margin: auto;

    @media (max-width: 1200px) {
        width: 300px;
        margin: 0;
    }

    @media (max-width: 668px) {
        width: 100px;
        margin: auto;
    }

    @media (max-width: 768px) {
        width: 300px;
        margin: auto;
    }

    @media (max-width: 425px) {
        width: 150px;
        margin: auto;
        min-height: 170px;
    }
`;

export const Seat = styled(Img)`
    width: 1000px;
    left: -100px;

    @media (max-width: 1200px) {
        width: 500px;
        left: -100px;
    }

    @media (max-width: 768px) {
        width: 500px;
        left: 0px;
    }

    @media (max-width: 668px) {
        width: 500px;
    }

    @media (max-width: 425px) {
        width: 500px;
    }

    @media (max-width: 375px) {
        width: 400px;
    }

    @media (max-width: 360px) {
        width: 300px;
    }

    @media (max-width: 320px) {
        width: 500px;
    }
`;

export const Text = styled.div`
    width: 500px;
    font-size: 20px;
    font-family: 'Poppins';
    font-weight: normal;

    @media (max-height: 850px) {
        width: 500px;
        font-size: medium;
        margin-top: 20px;
    }

    @media (max-width: 600px) {
        width: 300px;
        font-size: medium;
        margin-top: 20px;
        margin-right: 0px;
    }

    @media (max-width: 350px) {
        margin-right: 0px;
    }
`;

export const Button = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 500px;
    height: 50px;
    margin-top: 40px;
    background-color: #cc99ff;
    font-weight: 900;
    font-size: large;
    text-decoration: none;
    font-family: 'Ace Lift Bold', sans-serif;
    cursor: pointer;
    text-transform: uppercase;
    width: max-content;
    padding: 0px 10px;

    :active {
        background-color: #ac8ace;
    }

    :hover {
        background-color: #ac8ace;
    }

    @media (max-width: 768px) {
        display: none;
    }

    @media (max-width: 500px) {
        width: 300px;
        text-align: center;
        height: auto;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    @media (max-width: 350px) {
        margin-top: 10px;
    }
`;

export const StadeColor = styled.div`
    width: 250px;
    align-self: self-end;
    margin-right: 20px;
    overflow: visible !important;
    position: absolute;
    top: calc(95vh - 100px);
    transform: rotate(-15.2deg);
    right: 50px;

    @media (max-width: 768px) {
        overflow: hidden;
        display: none;
    }
`;

export const SmallStadeColor = styled.div`
    display: none;
    width: 100px;
    align-self: self-end;
    margin-right: 20px;
    overflow: visible !important;
    transform: rotate(-15.2deg);

    @media (max-width: 768px) {
        overflow: hidden;
        display: block;
        align-self: flex-end;
        position: relative;
        right: 0px;
        top: -10px;
    }
`;

export const Title = styled.div``;

export const Spinning = styled.div`
    width: 150px;
    position: relative;
    left: -75px;
    top: 50px;

    @media (max-width: 425px) {
        left: -40px;
        top: 75px;
    }

    @media (max-width: 360px) {
        left: -20px;
        top: 75px;
    }
`;

export const TextTitle = styled.div`
    font-family: 'Druk Wide Bold';
    font-style: normal;
    font-weight: 800;
    font-size: xxx-large;
    line-height: 43px;
    text-transform: uppercase;
    color: #551ef1;
    width: 700px;
    position: relative;
    top: -65px;

    @media (max-width: 1024px) {
        font-size: xx-large;
        width: 500px;
    }

    @media (max-width: 425px) {
        font-size: xx-large;
        width: 300px;
        left: 10px;
        top: -65px;
    }

    @media (max-width: 375px) {
        font-size: xx-large;
        width: 300px;
        left: 20px;
        top: -65px;
    }
`;
