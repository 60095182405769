import styled from 'styled-components';

export const ContentSectionSiegesImageSeat = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
        .cls-1 {
            fill: #686868;
        }
        #siege-1 {
            fill: ${(props) => (props.number >= 1 ? '#CC99FF' : '#686868')};
        }
        #siege-2 {
            fill: ${(props) => (props.number >= 2 ? '#CC99FF' : '#686868')};
        }
        #siege-3 {
            fill: ${(props) => (props.number >= 3 ? '#CC99FF' : '#686868')};
        }
        #siege-4 {
            fill: ${(props) => (props.number >= 4 ? '#CC99FF' : '#686868')};
        }
        #siege-5 {
            fill: ${(props) => (props.number >= 5 ? '#CC99FF' : '#686868')};
        }
        #siege-6 {
            fill: ${(props) => (props.number >= 6 ? '#CC99FF' : '#686868')};
        }
        #siege-7 {
            fill: ${(props) => (props.number >= 7 ? '#CC99FF' : '#686868')};
        }
        #siege-8 {
            fill: ${(props) => (props.number >= 8 ? '#CC99FF' : '#686868')};
        }
        #siege-9 {
            fill: ${(props) => (props.number >= 9 ? '#CC99FF' : '#686868')};
        }
        #siege-10 {
            fill: ${(props) => (props.number >= 10 ? '#CC99FF' : '#686868')};
        }
        #siege-11 {
            fill: ${(props) => (props.number >= 11 ? '#CC99FF' : '#686868')};
        }
        #siege-12 {
            fill: ${(props) => (props.number >= 12 ? '#CC99FF' : '#686868')};
        }
        #siege-13 {
            fill: ${(props) => (props.number >= 13 ? '#CC99FF' : '#686868')};
        }
        #siege-14 {
            fill: ${(props) => (props.number >= 14 ? '#CC99FF' : '#686868')};
        }
        #siege-15 {
            fill: ${(props) => (props.number >= 15 ? '#CC99FF' : '#686868')};
        }
        #siege-16 {
            fill: ${(props) => (props.number >= 16 ? '#CC99FF' : '#686868')};
        }
        #siege-17 {
            fill: ${(props) => (props.number >= 17 ? '#CC99FF' : '#686868')};
        }
        #siege-18 {
            fill: ${(props) => (props.number >= 18 ? '#CC99FF' : '#686868')};
        }
        #siege-19 {
            fill: ${(props) => (props.number >= 19 ? '#CC99FF' : '#686868')};
        }
        #siege-20 {
            fill: ${(props) => (props.number >= 20 ? '#CC99FF' : '#686868')};
        }
        #siege-21 {
            fill: ${(props) => (props.number >= 21 ? '#CC99FF' : '#686868')};
        }
        #siege-22 {
            fill: ${(props) => (props.number >= 22 ? '#CC99FF' : '#686868')};
        }
        #siege-23 {
            fill: ${(props) => (props.number >= 23 ? '#CC99FF' : '#686868')};
        }
        #siege-24 {
            fill: ${(props) => (props.number >= 24 ? '#CC99FF' : '#686868')};
        }
        #siege-25 {
            fill: ${(props) => (props.number >= 25 ? '#CC99FF' : '#686868')};
        }
        #siege-26 {
            fill: ${(props) => (props.number >= 26 ? '#CC99FF' : '#686868')};
        }
        #siege-27 {
            fill: ${(props) => (props.number >= 27 ? '#CC99FF' : '#686868')};
        }
        #siege-28 {
            fill: ${(props) => (props.number >= 28 ? '#CC99FF' : '#686868')};
        }
        #siege-29 {
            fill: ${(props) => (props.number >= 29 ? '#CC99FF' : '#686868')};
        }
        #siege-30 {
            fill: ${(props) => (props.number >= 30 ? '#CC99FF' : '#686868')};
        }
        #siege-31 {
            fill: ${(props) => (props.number >= 31 ? '#CC99FF' : '#686868')};
        }
        #siege-32 {
            fill: ${(props) => (props.number >= 32 ? '#CC99FF' : '#686868')};
        }
        #siege-33 {
            fill: ${(props) => (props.number >= 33 ? '#CC99FF' : '#686868')};
        }
        #siege-34 {
            fill: ${(props) => (props.number >= 34 ? '#CC99FF' : '#686868')};
        }
        #siege-35 {
            fill: ${(props) => (props.number >= 35 ? '#CC99FF' : '#686868')};
        }
        #siege-36 {
            fill: ${(props) => (props.number >= 36 ? '#CC99FF' : '#686868')};
        }
        #siege-37 {
            fill: ${(props) => (props.number >= 37 ? '#CC99FF' : '#686868')};
        }
        #siege-38 {
            fill: ${(props) => (props.number >= 38 ? '#CC99FF' : '#686868')};
        }
        #siege-39 {
            fill: ${(props) => (props.number >= 39 ? '#CC99FF' : '#686868')};
        }
        #siege-40 {
            fill: ${(props) => (props.number >= 40 ? '#CC99FF' : '#686868')};
        }
        #siege-41 {
            fill: ${(props) => (props.number >= 41 ? '#CC99FF' : '#686868')};
        }
        #siege-42 {
            fill: ${(props) => (props.number >= 42 ? '#CC99FF' : '#686868')};
        }
        #siege-43 {
            fill: ${(props) => (props.number >= 43 ? '#CC99FF' : '#686868')};
        }
        #siege-44 {
            fill: ${(props) => (props.number >= 44 ? '#CC99FF' : '#686868')};
        }
        #siege-45 {
            fill: ${(props) => (props.number >= 45 ? '#CC99FF' : '#686868')};
        }
        #siege-46 {
            fill: ${(props) => (props.number >= 46 ? '#CC99FF' : '#686868')};
        }
        #siege-47 {
            fill: ${(props) => (props.number >= 47 ? '#CC99FF' : '#686868')};
        }
        #siege-48 {
            fill: ${(props) => (props.number >= 48 ? '#CC99FF' : '#686868')};
        }
        #siege-49 {
            fill: ${(props) => (props.number >= 49 ? '#CC99FF' : '#686868')};
        }
        #siege-50 {
            fill: ${(props) => (props.number >= 50 ? '#CC99FF' : '#686868')};
        }
        #siege-51 {
            fill: ${(props) => (props.number >= 51 ? '#CC99FF' : '#686868')};
        }
        #siege-52 {
            fill: ${(props) => (props.number >= 52 ? '#CC99FF' : '#686868')};
        }
        #siege-53 {
            fill: ${(props) => (props.number >= 53 ? '#CC99FF' : '#686868')};
        }
        #siege-54 {
            fill: ${(props) => (props.number >= 54 ? '#CC99FF' : '#686868')};
        }
        #siege-55 {
            fill: ${(props) => (props.number >= 55 ? '#CC99FF' : '#686868')};
        }
        #siege-56 {
            fill: ${(props) => (props.number >= 56 ? '#CC99FF' : '#686868')};
        }
        #siege-57 {
            fill: ${(props) => (props.number >= 57 ? '#CC99FF' : '#686868')};
        }
        #siege-58 {
            fill: ${(props) => (props.number >= 58 ? '#CC99FF' : '#686868')};
        }
        #siege-59 {
            fill: ${(props) => (props.number >= 59 ? '#CC99FF' : '#686868')};
        }
        #siege-60 {
            fill: ${(props) => (props.number >= 60 ? '#CC99FF' : '#686868')};
        }
        #siege-61 {
            fill: ${(props) => (props.number >= 61 ? '#CC99FF' : '#686868')};
        }
        #siege-62 {
            fill: ${(props) => (props.number >= 62 ? '#CC99FF' : '#686868')};
        }
        #siege-63 {
            fill: ${(props) => (props.number >= 63 ? '#CC99FF' : '#686868')};
        }
        #siege-64 {
            fill: ${(props) => (props.number >= 64 ? '#CC99FF' : '#686868')};
        }
        #siege-65 {
            fill: ${(props) => (props.number >= 65 ? '#CC99FF' : '#686868')};
        }
        #siege-66 {
            fill: ${(props) => (props.number >= 66 ? '#CC99FF' : '#686868')};
        }
        #siege-67 {
            fill: ${(props) => (props.number >= 67 ? '#CC99FF' : '#686868')};
        }
        #siege-68 {
            fill: ${(props) => (props.number >= 68 ? '#CC99FF' : '#686868')};
        }
        #siege-69 {
            fill: ${(props) => (props.number >= 69 ? '#CC99FF' : '#686868')};
        }
        #siege-70 {
            fill: ${(props) => (props.number >= 70 ? '#CC99FF' : '#686868')};
        }
        #siege-71 {
            fill: ${(props) => (props.number >= 71 ? '#CC99FF' : '#686868')};
        }
        #siege-72 {
            fill: ${(props) => (props.number >= 72 ? '#CC99FF' : '#686868')};
        }
        #siege-73 {
            fill: ${(props) => (props.number >= 73 ? '#CC99FF' : '#686868')};
        }
        #siege-74 {
            fill: ${(props) => (props.number >= 74 ? '#CC99FF' : '#686868')};
        }
        #siege-75 {
            fill: ${(props) => (props.number >= 75 ? '#CC99FF' : '#686868')};
        }
        #siege-76 {
            fill: ${(props) => (props.number >= 76 ? '#CC99FF' : '#686868')};
        }
        #siege-77 {
            fill: ${(props) => (props.number >= 77 ? '#CC99FF' : '#686868')};
        }
        #siege-78 {
            fill: ${(props) => (props.number >= 78 ? '#CC99FF' : '#686868')};
        }
        #siege-79 {
            fill: ${(props) => (props.number >= 79 ? '#CC99FF' : '#686868')};
        }
        #siege-80 {
            fill: ${(props) => (props.number >= 80 ? '#CC99FF' : '#686868')};
        }
        #siege-81 {
            fill: ${(props) => (props.number >= 81 ? '#CC99FF' : '#686868')};
        }
        #siege-82 {
            fill: ${(props) => (props.number >= 82 ? '#CC99FF' : '#686868')};
        }
        #siege-83 {
            fill: ${(props) => (props.number >= 83 ? '#CC99FF' : '#686868')};
        }
        #siege-84 {
            fill: ${(props) => (props.number >= 84 ? '#CC99FF' : '#686868')};
        }
        #siege-85 {
            fill: ${(props) => (props.number >= 85 ? '#CC99FF' : '#686868')};
        }
        #siege-86 {
            fill: ${(props) => (props.number >= 86 ? '#CC99FF' : '#686868')};
        }
        #siege-87 {
            fill: ${(props) => (props.number >= 87 ? '#CC99FF' : '#686868')};
        }
        #siege-88 {
            fill: ${(props) => (props.number >= 88 ? '#CC99FF' : '#686868')};
        }
        #siege-89 {
            fill: ${(props) => (props.number >= 89 ? '#CC99FF' : '#686868')};
        }
        #siege-90 {
            fill: ${(props) => (props.number >= 90 ? '#CC99FF' : '#686868')};
        }
        #siege-91 {
            fill: ${(props) => (props.number >= 91 ? '#CC99FF' : '#686868')};
        }
        #siege-92 {
            fill: ${(props) => (props.number >= 92 ? '#CC99FF' : '#686868')};
        }
        #siege-93 {
            fill: ${(props) => (props.number >= 93 ? '#CC99FF' : '#686868')};
        }
        #siege-94 {
            fill: ${(props) => (props.number >= 94 ? '#CC99FF' : '#686868')};
        }
        #siege-95 {
            fill: ${(props) => (props.number >= 95 ? '#CC99FF' : '#686868')};
        }
        #siege-96 {
            fill: ${(props) => (props.number >= 96 ? '#CC99FF' : '#686868')};
        }
        #siege-97 {
            fill: ${(props) => (props.number >= 97 ? '#CC99FF' : '#686868')};
        }
        #siege-98 {
            fill: ${(props) => (props.number >= 98 ? '#CC99FF' : '#686868')};
        }
        #siege-99 {
            fill: ${(props) => (props.number >= 99 ? '#CC99FF' : '#686868')};
        }
        #siege-100 {
            fill: ${(props) => (props.number >= 100 ? '#CC99FF' : '#686868')};
        }
        #siege-101 {
            fill: ${(props) => (props.number >= 101 ? '#CC99FF' : '#686868')};
        }
        #siege-102 {
            fill: ${(props) => (props.number >= 102 ? '#CC99FF' : '#686868')};
        }
        #siege-103 {
            fill: ${(props) => (props.number >= 103 ? '#CC99FF' : '#686868')};
        }
        #siege-104 {
            fill: ${(props) => (props.number >= 104 ? '#CC99FF' : '#686868')};
        }
        #siege-105 {
            fill: ${(props) => (props.number >= 105 ? '#CC99FF' : '#686868')};
        }
        #siege-106 {
            fill: ${(props) => (props.number >= 106 ? '#CC99FF' : '#686868')};
        }
        #siege-107 {
            fill: ${(props) => (props.number >= 107 ? '#CC99FF' : '#686868')};
        }
        #siege-108 {
            fill: ${(props) => (props.number >= 108 ? '#CC99FF' : '#686868')};
        }
        #siege-109 {
            fill: ${(props) => (props.number >= 109 ? '#CC99FF' : '#686868')};
        }
        #siege-110 {
            fill: ${(props) => (props.number >= 110 ? '#CC99FF' : '#686868')};
        }
        #siege-111 {
            fill: ${(props) => (props.number >= 111 ? '#CC99FF' : '#686868')};
        }
        #siege-112 {
            fill: ${(props) => (props.number >= 112 ? '#CC99FF' : '#686868')};
        }
        #siege-113 {
            fill: ${(props) => (props.number >= 113 ? '#CC99FF' : '#686868')};
        }
        #siege-114 {
            fill: ${(props) => (props.number >= 114 ? '#CC99FF' : '#686868')};
        }
        #siege-115 {
            fill: ${(props) => (props.number >= 115 ? '#CC99FF' : '#686868')};
        }
        #siege-116 {
            fill: ${(props) => (props.number >= 116 ? '#CC99FF' : '#686868')};
        }
        #siege-117 {
            fill: ${(props) => (props.number >= 117 ? '#CC99FF' : '#686868')};
        }
        #siege-118 {
            fill: ${(props) => (props.number >= 118 ? '#CC99FF' : '#686868')};
        }
        #siege-119 {
            fill: ${(props) => (props.number >= 119 ? '#CC99FF' : '#686868')};
        }
        #siege-120 {
            fill: ${(props) => (props.number >= 120 ? '#CC99FF' : '#686868')};
        }
        #siege-121 {
            fill: ${(props) => (props.number >= 121 ? '#CC99FF' : '#686868')};
        }
        #siege-122 {
            fill: ${(props) => (props.number >= 122 ? '#CC99FF' : '#686868')};
        }
        #siege-123 {
            fill: ${(props) => (props.number >= 123 ? '#CC99FF' : '#686868')};
        }
        #siege-124 {
            fill: ${(props) => (props.number >= 124 ? '#CC99FF' : '#686868')};
        }
        #siege-125 {
            fill: ${(props) => (props.number >= 125 ? '#CC99FF' : '#686868')};
        }
        #siege-126 {
            fill: ${(props) => (props.number >= 126 ? '#CC99FF' : '#686868')};
        }
        #siege-127 {
            fill: ${(props) => (props.number >= 127 ? '#CC99FF' : '#686868')};
        }
        #siege-128 {
            fill: ${(props) => (props.number >= 128 ? '#CC99FF' : '#686868')};
        }
        #siege-129 {
            fill: ${(props) => (props.number >= 129 ? '#CC99FF' : '#686868')};
        }
        #siege-130 {
            fill: ${(props) => (props.number >= 130 ? '#CC99FF' : '#686868')};
        }
        #siege-131 {
            fill: ${(props) => (props.number >= 131 ? '#CC99FF' : '#686868')};
        }
        #siege-132 {
            fill: ${(props) => (props.number >= 132 ? '#CC99FF' : '#686868')};
        }
        #siege-133 {
            fill: ${(props) => (props.number >= 133 ? '#CC99FF' : '#686868')};
        }
        #siege-134 {
            fill: ${(props) => (props.number >= 134 ? '#CC99FF' : '#686868')};
        }
        #siege-135 {
            fill: ${(props) => (props.number >= 135 ? '#CC99FF' : '#686868')};
        }
        #siege-136 {
            fill: ${(props) => (props.number >= 136 ? '#CC99FF' : '#686868')};
        }
        #siege-137 {
            fill: ${(props) => (props.number >= 137 ? '#CC99FF' : '#686868')};
        }
        #siege-138 {
            fill: ${(props) => (props.number >= 138 ? '#CC99FF' : '#686868')};
        }
        #siege-139 {
            fill: ${(props) => (props.number >= 139 ? '#CC99FF' : '#686868')};
        }
        #siege-140 {
            fill: ${(props) => (props.number >= 140 ? '#CC99FF' : '#686868')};
        }
        #siege-141 {
            fill: ${(props) => (props.number >= 141 ? '#CC99FF' : '#686868')};
        }
        #siege-142 {
            fill: ${(props) => (props.number >= 142 ? '#CC99FF' : '#686868')};
        }
        #siege-143 {
            fill: ${(props) => (props.number >= 143 ? '#CC99FF' : '#686868')};
        }
        #siege-144 {
            fill: ${(props) => (props.number >= 144 ? '#CC99FF' : '#686868')};
        }
        #siege-145 {
            fill: ${(props) => (props.number >= 145 ? '#CC99FF' : '#686868')};
        }
        #siege-146 {
            fill: ${(props) => (props.number >= 146 ? '#CC99FF' : '#686868')};
        }
        #siege-147 {
            fill: ${(props) => (props.number >= 147 ? '#CC99FF' : '#686868')};
        }
        #siege-148 {
            fill: ${(props) => (props.number >= 148 ? '#CC99FF' : '#686868')};
        }
        #siege-149 {
            fill: ${(props) => (props.number >= 149 ? '#CC99FF' : '#686868')};
        }
        #siege-150 {
            fill: ${(props) => (props.number >= 150 ? '#CC99FF' : '#686868')};
        }
        #siege-151 {
            fill: ${(props) => (props.number >= 151 ? '#CC99FF' : '#686868')};
        }
        #siege-152 {
            fill: ${(props) => (props.number >= 152 ? '#CC99FF' : '#686868')};
        }
        #siege-153 {
            fill: ${(props) => (props.number >= 153 ? '#CC99FF' : '#686868')};
        }
        #siege-154 {
            fill: ${(props) => (props.number >= 154 ? '#CC99FF' : '#686868')};
        }
        #siege-155 {
            fill: ${(props) => (props.number >= 155 ? '#CC99FF' : '#686868')};
        }
        #siege-156 {
            fill: ${(props) => (props.number >= 156 ? '#CC99FF' : '#686868')};
        }
        #siege-157 {
            fill: ${(props) => (props.number >= 157 ? '#CC99FF' : '#686868')};
        }
        #siege-158 {
            fill: ${(props) => (props.number >= 158 ? '#CC99FF' : '#686868')};
        }
        #siege-159 {
            fill: ${(props) => (props.number >= 159 ? '#CC99FF' : '#686868')};
        }
        #siege-160 {
            fill: ${(props) => (props.number >= 160 ? '#CC99FF' : '#686868')};
        }
        #siege-161 {
            fill: ${(props) => (props.number >= 161 ? '#CC99FF' : '#686868')};
        }
        #siege-162 {
            fill: ${(props) => (props.number >= 162 ? '#CC99FF' : '#686868')};
        }
        #siege-163 {
            fill: ${(props) => (props.number >= 163 ? '#CC99FF' : '#686868')};
        }
        #siege-164 {
            fill: ${(props) => (props.number >= 164 ? '#CC99FF' : '#686868')};
        }
        #siege-165 {
            fill: ${(props) => (props.number >= 165 ? '#CC99FF' : '#686868')};
        }
        #siege-166 {
            fill: ${(props) => (props.number >= 166 ? '#CC99FF' : '#686868')};
        }
        #siege-167 {
            fill: ${(props) => (props.number >= 167 ? '#CC99FF' : '#686868')};
        }
        #siege-168 {
            fill: ${(props) => (props.number >= 168 ? '#CC99FF' : '#686868')};
        }
        #siege-169 {
            fill: ${(props) => (props.number >= 169 ? '#CC99FF' : '#686868')};
        }
        #siege-170 {
            fill: ${(props) => (props.number >= 170 ? '#CC99FF' : '#686868')};
        }
        #siege-171 {
            fill: ${(props) => (props.number >= 171 ? '#CC99FF' : '#686868')};
        }
        #siege-172 {
            fill: ${(props) => (props.number >= 172 ? '#CC99FF' : '#686868')};
        }
        #siege-173 {
            fill: ${(props) => (props.number >= 173 ? '#CC99FF' : '#686868')};
        }
        #siege-174 {
            fill: ${(props) => (props.number >= 174 ? '#CC99FF' : '#686868')};
        }
        #siege-175 {
            fill: ${(props) => (props.number >= 175 ? '#CC99FF' : '#686868')};
        }
        #siege-176 {
            fill: ${(props) => (props.number >= 176 ? '#CC99FF' : '#686868')};
        }
        #siege-177 {
            fill: ${(props) => (props.number >= 177 ? '#CC99FF' : '#686868')};
        }
        #siege-178 {
            fill: ${(props) => (props.number >= 178 ? '#CC99FF' : '#686868')};
        }
        #siege-179 {
            fill: ${(props) => (props.number >= 179 ? '#CC99FF' : '#686868')};
        }
        #siege-180 {
            fill: ${(props) => (props.number >= 180 ? '#CC99FF' : '#686868')};
        }
        #siege-181 {
            fill: ${(props) => (props.number >= 181 ? '#CC99FF' : '#686868')};
        }
        #siege-182 {
            fill: ${(props) => (props.number >= 182 ? '#CC99FF' : '#686868')};
        }
        #siege-183 {
            fill: ${(props) => (props.number >= 183 ? '#CC99FF' : '#686868')};
        }
        #siege-184 {
            fill: ${(props) => (props.number >= 184 ? '#CC99FF' : '#686868')};
        }
        #siege-185 {
            fill: ${(props) => (props.number >= 185 ? '#CC99FF' : '#686868')};
        }
        #siege-186 {
            fill: ${(props) => (props.number >= 186 ? '#CC99FF' : '#686868')};
        }
        #siege-187 {
            fill: ${(props) => (props.number >= 187 ? '#CC99FF' : '#686868')};
        }
        #siege-188 {
            fill: ${(props) => (props.number >= 188 ? '#CC99FF' : '#686868')};
        }
        #siege-189 {
            fill: ${(props) => (props.number >= 189 ? '#CC99FF' : '#686868')};
        }
        #siege-190 {
            fill: ${(props) => (props.number >= 190 ? '#CC99FF' : '#686868')};
        }
        #siege-191 {
            fill: ${(props) => (props.number >= 191 ? '#CC99FF' : '#686868')};
        }
        #siege-192 {
            fill: ${(props) => (props.number >= 192 ? '#CC99FF' : '#686868')};
        }
        #siege-193 {
            fill: ${(props) => (props.number >= 193 ? '#CC99FF' : '#686868')};
        }
        #siege-194 {
            fill: ${(props) => (props.number >= 194 ? '#CC99FF' : '#686868')};
        }
        #siege-195 {
            fill: ${(props) => (props.number >= 195 ? '#CC99FF' : '#686868')};
        }
        #siege-196 {
            fill: ${(props) => (props.number >= 196 ? '#CC99FF' : '#686868')};
        }
        #siege-197 {
            fill: ${(props) => (props.number >= 197 ? '#CC99FF' : '#686868')};
        }
        #siege-198 {
            fill: ${(props) => (props.number >= 198 ? '#CC99FF' : '#686868')};
        }
        #siege-199 {
            fill: ${(props) => (props.number >= 199 ? '#CC99FF' : '#686868')};
        }
        #siege-200 {
            fill: ${(props) => (props.number >= 200 ? '#CC99FF' : '#686868')};
        }
        #siege-201 {
            fill: ${(props) => (props.number >= 201 ? '#CC99FF' : '#686868')};
        }
        #siege-202 {
            fill: ${(props) => (props.number >= 202 ? '#CC99FF' : '#686868')};
        }
        #siege-203 {
            fill: ${(props) => (props.number >= 203 ? '#CC99FF' : '#686868')};
        }
        #siege-204 {
            fill: ${(props) => (props.number >= 204 ? '#CC99FF' : '#686868')};
        }
        #siege-205 {
            fill: ${(props) => (props.number >= 205 ? '#CC99FF' : '#686868')};
        }
        #siege-206 {
            fill: ${(props) => (props.number >= 206 ? '#CC99FF' : '#686868')};
        }
        #siege-207 {
            fill: ${(props) => (props.number >= 207 ? '#CC99FF' : '#686868')};
        }
        #siege-208 {
            fill: ${(props) => (props.number >= 208 ? '#CC99FF' : '#686868')};
        }
        #siege-209 {
            fill: ${(props) => (props.number >= 209 ? '#CC99FF' : '#686868')};
        }
        #siege-210 {
            fill: ${(props) => (props.number >= 210 ? '#CC99FF' : '#686868')};
        }
        #siege-211 {
            fill: ${(props) => (props.number >= 211 ? '#CC99FF' : '#686868')};
        }
        #siege-212 {
            fill: ${(props) => (props.number >= 212 ? '#CC99FF' : '#686868')};
        }
        #siege-213 {
            fill: ${(props) => (props.number >= 213 ? '#CC99FF' : '#686868')};
        }
        #siege-214 {
            fill: ${(props) => (props.number >= 214 ? '#CC99FF' : '#686868')};
        }
        #siege-215 {
            fill: ${(props) => (props.number >= 215 ? '#CC99FF' : '#686868')};
        }
        #siege-216 {
            fill: ${(props) => (props.number >= 216 ? '#CC99FF' : '#686868')};
        }
        #siege-217 {
            fill: ${(props) => (props.number >= 217 ? '#CC99FF' : '#686868')};
        }
        #siege-218 {
            fill: ${(props) => (props.number >= 218 ? '#CC99FF' : '#686868')};
        }
        #siege-219 {
            fill: ${(props) => (props.number >= 219 ? '#CC99FF' : '#686868')};
        }
        #siege-220 {
            fill: ${(props) => (props.number >= 220 ? '#CC99FF' : '#686868')};
        }
        #siege-221 {
            fill: ${(props) => (props.number >= 221 ? '#CC99FF' : '#686868')};
        }
        #siege-222 {
            fill: ${(props) => (props.number >= 222 ? '#CC99FF' : '#686868')};
        }
        #siege-223 {
            fill: ${(props) => (props.number >= 223 ? '#CC99FF' : '#686868')};
        }
        #siege-224 {
            fill: ${(props) => (props.number >= 224 ? '#CC99FF' : '#686868')};
        }
        #siege-225 {
            fill: ${(props) => (props.number >= 225 ? '#CC99FF' : '#686868')};
        }
        #siege-226 {
            fill: ${(props) => (props.number >= 226 ? '#CC99FF' : '#686868')};
        }
        #siege-227 {
            fill: ${(props) => (props.number >= 227 ? '#CC99FF' : '#686868')};
        }
        #siege-228 {
            fill: ${(props) => (props.number >= 228 ? '#CC99FF' : '#686868')};
        }
        #siege-229 {
            fill: ${(props) => (props.number >= 229 ? '#CC99FF' : '#686868')};
        }
        #siege-230 {
            fill: ${(props) => (props.number >= 230 ? '#CC99FF' : '#686868')};
        }
        #siege-231 {
            fill: ${(props) => (props.number >= 231 ? '#CC99FF' : '#686868')};
        }
        #siege-232 {
            fill: ${(props) => (props.number >= 232 ? '#CC99FF' : '#686868')};
        }
        #siege-233 {
            fill: ${(props) => (props.number >= 233 ? '#CC99FF' : '#686868')};
        }
        #siege-234 {
            fill: ${(props) => (props.number >= 234 ? '#CC99FF' : '#686868')};
        }
        #siege-235 {
            fill: ${(props) => (props.number >= 235 ? '#CC99FF' : '#686868')};
        }
        #siege-236 {
            fill: ${(props) => (props.number >= 236 ? '#CC99FF' : '#686868')};
        }
        #siege-237 {
            fill: ${(props) => (props.number >= 237 ? '#CC99FF' : '#686868')};
        }
        #siege-238 {
            fill: ${(props) => (props.number >= 238 ? '#CC99FF' : '#686868')};
        }
        #siege-239 {
            fill: ${(props) => (props.number >= 239 ? '#CC99FF' : '#686868')};
        }
        #siege-240 {
            fill: ${(props) => (props.number >= 240 ? '#CC99FF' : '#686868')};
        }
    }
`;
