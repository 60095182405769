import React, { useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Page, Columns, Titre, SubTitre, Carousel } from './styles';

export const TakedSeats: React.FC = () => {
    const { googleSheet } = useStaticQuery(
        graphql`
            query {
                googleSheet {
                    billets {
                        nom
                        vendus
                    }
                }
            }
        `,
    );

    const customerLogo = useRef(null);
    const array = googleSheet.billets;

    return (
        <Page>
            <Carousel length={array.length}>
                {array.map(({ nom, vendus }, index) => {
                    return (
                        <Columns
                            ref={customerLogo}
                            key={`Columns${nom}${vendus}`}
                            style={{ zIndex: index + 1 }}
                        >
                            <Titre key={`Titre${nom}${vendus}`}>{nom}</Titre>
                            <SubTitre key={`SubTitre${nom}${vendus}`}>
                                {vendus} siège{vendus > 1 && 's'}
                            </SubTitre>
                        </Columns>
                    );
                })}
            </Carousel>
        </Page>
    );
};
