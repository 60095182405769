import styled, { keyframes } from 'styled-components';
import Img from 'gatsby-image';

export const Page = styled.div`
    min-height: 10vh;
    width: 100%;
    background-color: #191919;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    justify-content: flex-start;

    @media (max-height: 640px) {
        min-height: 20vh;
    }

    @media (max-height: 600px) {
        padding: 20px 10px;
    }

    @media (max-width: 768px) {
        flex-direction: row;
    }
    @media (max-width: 450px) {
        padding: 20px 10px;
    }
`;

export const Columns = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0px 30px;
    flex: 1;

    @media (max-width: 450px) {
        padding: 0px 20px;
    }
`;

export const Titre = styled.div`
    font-weight: 900;
    font-size: large;
    margin: auto;
    margin-bottom: 0px;
    line-height: 34px;
    color: #12ff82;
    text-align: center;
    font-family: 'Ace Lift Regular', sans-serif;
    text-transform: uppercase;

    @media (max-height: 600px) {
        font-size: medium;
        line-height: 24px;
    }

    @media (max-width: 450px) {
        font-size: small;
    }
`;

export const SubTitre = styled.div`
    font-weight: 900;
    font-size: medium;
    margin: auto;
    margin-top: 0px;
    color: white;
    font-family: 'Ace Lift Regular', sans-serif;
    text-transform: uppercase;

    @media (max-height: 600px) {
        margin: 10px auto;
        font-size: small;
    }

    @media (max-width: 450px) {
        font-size: x-small;
    }
`;

// Animation
const scroll = (length) => keyframes`
	0% { transform: translateX(calc(0px)) }
	100% { transform: translateX(calc(-220px * ${length}))}
`;

export const Carousel = styled.div`
    animation: ${(props) => scroll(props.length)} ${(props) => props.length * 5}s linear infinite;
    display: flex;
    justify-content: flex-start;
    white-space: nowrap;
`;
