import styled from 'styled-components';
import Img from 'gatsby-image';

export const Page = styled.div`
    min-height: 80vh;
    background-color: #fff;
    padding: 0px 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 450px) {
        padding: 40px 20px;
    }
`;

export const Logo = styled(Img)`
    width: 100%;
    margin: 0 20px 20px 0;

    @media (max-width: 1024px) {
        width: 100%;
    }
`;

export const Container = styled.div`
    display: flex;
    align-items: center;

    justify-content: space-evenly;
    width: 100%;

    @media (max-width: 768px) {
        flex-direction: column-reverse;
    }
`;

export const ContainerText = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;

    justify-content: space-evenly;
    width: 50%;

    @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
    }
`;

export const Info = styled(Img)`
    width: 100%;
    max-width: 568px;
    height: auto;

    @media (max-width: 1440px) {
        width: 50%;
        height: auto;
    }

    @media (max-width: 1024px) {
        width: 50%;
        height: auto;
    }

    @media (max-width: 768px) {
        width: 100%;
        height: auto;
    }
`;

export const Door89 = styled(Img)`
    width: 130px;

    @media (max-width: 768px) {
        width: 80px;
    }
`;

export const Text = styled.div`
    font-size: 19px;
    margin-right: 20px;
    line-height: 27px;
    font-family: 'Poppins';
    font-weight: normal;

    @media (max-height: 600px) {
        font-size: medium;
        margin-top: 10px;
    }

    @media (max-width: 650px) {
        font-size: small;
        margin-top: 10px;
        margin-right: 0px;
    }
`;

export const Title = styled.div``;

export const Spinning = styled.div`
    width: 150px;
    position: relative;
    left: -75px;
    position: relative;
    top: 50px;

    @media (max-width: 1024px) {
        left: -40px;
    }

    @media (max-width: 768px) {
        left: -100px;
    }

    @media (max-width: 425px) {
        left: 0px;
    }
`;

export const TextTitle = styled.div`
    font-family: 'Druk Wide Bold';
    font-style: normal;
    font-weight: 800;
    font-size: xxx-large;
    line-height: 43px;
    text-transform: uppercase;
    color: #551ef1;
    width: 500px;
    position: relative;
    top: -65px;

    @media (max-width: 1440px) {
        width: 700px;
        top: -70px;
    }

    @media (max-width: 1024px) {
        font-size: xx-large;
        width: auto;
        left: 0px;
        top: -65px;
    }

    @media (max-width: 768px) {
        left: -40px;
        font-size: xx-large;
        top: -45px;
    }

    @media (max-width: 425px) {
        font-size: x-large;
        width: auto;
        left: 30px;
        top: -65px;
    }
`;
