import React from 'react';

export const Rings: React.FC = () => {
    return (
        <svg
            width="318"
            height="85"
            viewBox="0 0 318 85"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M159.216 73.5532C233.688 73.5532 294.06 57.626 294.06 37.9789C294.06 18.3317 233.688 2.40454 159.216 2.40454C84.7444 2.40454 24.373 18.3317 24.373 37.9789C24.373 57.626 84.7444 73.5532 159.216 73.5532Z"
                stroke="#12FF82"
                stroke-width="3"
                stroke-miterlimit="10"
            />
            <path
                d="M159.216 65.4865C222.221 65.4865 273.297 52.2547 273.297 35.9325C273.297 19.6102 222.221 6.37842 159.216 6.37842C96.211 6.37842 45.1351 19.6102 45.1351 35.9325C45.1351 52.2547 96.211 65.4865 159.216 65.4865Z"
                stroke="#12FF82"
                stroke-width="3"
                stroke-miterlimit="10"
            />
            <path
                d="M159.216 58.2288C210.499 58.2288 252.072 47.4473 252.072 34.1477C252.072 20.8481 210.499 10.0667 159.216 10.0667C107.933 10.0667 66.3601 20.8481 66.3601 34.1477C66.3601 47.4473 107.933 58.2288 159.216 58.2288Z"
                stroke="#12FF82"
                stroke-width="3"
                stroke-miterlimit="10"
            />
            <path
                d="M159.216 83C246.044 83 316.432 64.8675 316.432 42.5C316.432 20.1325 246.044 2 159.216 2C72.3881 2 2 20.1325 2 42.5C2 64.8675 72.3881 83 159.216 83Z"
                stroke="#12FF82"
                stroke-width="3"
                stroke-miterlimit="10"
            />
        </svg>
    );
};
