/* eslint-disable no-irregular-whitespace */
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import stadeColor from './stade-couleur.gif';
import spinning from './spinning-burst.gif';

import {
    Page,
    Seat,
    ContainerSeat,
    Container,
    ContainerText,
    Text,
    Button,
    Title,
    Spinning,
    TextTitle,
    StadeColor,
    SmallStadeColor,
} from './styles';

export const Information: React.FC = () => {
    const { seat } = useStaticQuery(
        graphql`
            query {
                seat: file(relativePath: { eq: "seat.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 1550) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        `,
    );

    const seatImageData = seat.childImageSharp.fluid;

    return (
        <Page>
            <StadeColor>
                {/* eslint-disable-next-line jsx-a11y/alt-text */}
                <img src={stadeColor} />
            </StadeColor>
            <Container>
                <ContainerText>
                    <Title>
                        <Spinning>
                            {/* eslint-disable-next-line jsx-a11y/alt-text */}
                            <img src={spinning} />
                        </Spinning>
                        <TextTitle>Restez chez vous en grand nombre</TextTitle>
                    </Title>
                    <Text style={{ marginTop: 0 }}>
                        L’an dernier on a manqué de place pour le NoShow du Refuge, un non-événement
                        où chacun restait chez soi pour soutenir les jeunes en situation
                        d'itinérance.
                    </Text>
                    <Text>
                        Cette année, comme on ne peut toujours pas faire le show devant public et
                        qu’on veut de la place en masse, on ne prend pas de chances. On s’en va au
                        Stade olympique et avec votre aide on va remplir les 56 000 places.
                    </Text>
                    <Button onClick={() => document.getElementById('Seats').scrollIntoView()}>
                        Achetez vos billets symboliques
                    </Button>
                    <SmallStadeColor>
                        {/* eslint-disable-next-line jsx-a11y/alt-text */}
                        <img src={stadeColor} />
                    </SmallStadeColor>
                </ContainerText>
                <Seat fluid={seatImageData} />
            </Container>
        </Page>
    );
};
