import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import spinning from './spinning-burst.gif';

import { Page, Container, ContainerText, Text, Info, Title, Spinning, TextTitle } from './styles';

export const MoreInfo: React.FC = () => {
    const { info } = useStaticQuery(
        graphql`
            query {
                info: file(relativePath: { eq: "door.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 600) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        `,
    );

    const infoImageData = info.childImageSharp.fluid;

    return (
        <Page>
            <Container>
                <ContainerText>
                    <Title>
                        <Spinning>
                            {/* eslint-disable-next-line jsx-a11y/alt-text */}
                            <img src={spinning} />
                        </Spinning>
                        <TextTitle>À propos du refuge</TextTitle>
                    </Title>
                    <Text>
                        Le Refuge des Jeunes de Montréal est un accueil de jour, de soir et de nuit
                        fondé en 1989. Sa mission est de venir en aide à des jeunes hommes sans-abri
                        et en difficulté de 17 à 25 ans. Le Refuge offre les services suivants :
                        accueil, références, suivi psychosocial de jour et logement social avec
                        soutien communautaire (21 unités).
                    </Text>
                    <br />
                    <Text>
                        Le Refuge a accueilli en 30 ans 21 039 jeunes hommes dont plus de 165
                        d’entre eux ont eu accès à un logement social avec soutien communautaire.
                        Ces jeunes viennent de partout : de Montréal, du Québec, d’autres provinces
                        canadiennes et d’autres pays. Toutes les actions du Refuge visent leur
                        intégration et l’amélioration de leurs conditions de vie.
                    </Text>
                </ContainerText>
                <Info fluid={infoImageData} />
            </Container>
        </Page>
    );
};
