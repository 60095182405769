import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';

export const AcceuilBG = styled(BackgroundImage)`
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #121315;
    background-size: cover;

    @media (max-height: 640px) {
        min-height: 100vh;
    }

    @media (max-width: 425px) {
        padding: 20px;
        padding-top: 400px;
        background-size: contain !important;
    }
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    position: absolute;
    width: 100%;
    top: 15px;
    z-index: 2;
    padding-top: 0px;

    @media (max-width: 475px) {
        padding: 0px;
        width: 90%;
    }
`;

export const Logo = styled(Img)`
    width: 150px;
    align-self: center;
    @media (max-width: 1024px) {
        width: 120px;
    }

    @media (max-height: 850px) {
        width: 100px;
    }
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;

    @media (max-width: 768px) {
        flex-direction: column-reverse;
    }
`;

export const Edition = styled.div`
    width: 200px;
    transform: rotate(-8.46deg);

    @media (max-width: 475px) {
        width: 100px;
    }
`;

export const Title = styled(Img)`
    margin-top: -50px;
    width: 750px;

    @media (max-height: 850px) {
        width: 400px;
        margin-top: 0px;
    }

    @media (max-width: 768px) {
        width: 400px;
    }
    @media (max-width: 500px) {
        width: 350px;
        margin-top: 0px;
    }
    @media (max-width: 450px) {
        width: 300px;
        margin-top: 20px;
    }
    @media (max-width: 350px) {
        width: 250px;
    }
`;

export const Button = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 62px;
    margin: 0px auto;
    margin-top: 80px;
    background-color: #12ff82;
    font-weight: 900;
    text-decoration: none;
    font-size: large;
    font-family: 'Ace Lift Regular', sans-serif;
    cursor: pointer;
    padding: 0px 20px;
    padding-top: 5px;

    :active {
        background-color: #13c265;
    }

    :hover {
        background-color: #13c265;
    }

    @media (max-height: 850px) {
        margin-top: 20px;
    }

    @media (max-width: 500px) {
        width: auto;
        text-align: center;
        font-size: medium;
    }

    @media (max-width: 350px) {
        width: auto;
        text-align: center;
        font-size: medium;
    }
`;
