import styled, { keyframes } from 'styled-components';
import Img from 'gatsby-image';

export const Page = styled.div`
    min-height: 45vh;
    width: 100%;
    background-color: #191919;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;

    @media (max-height: 640px) {
        min-height: 20vh;
    }

    @media (max-height: 600px) {
        padding: 20px 10px;
    }

    @media (max-width: 450px) {
        padding: 20px 10px;
    }
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 0px 30px;
    flex: 1;

    @media (max-width: 450px) {
        flex-direction: column;
    }
`;

export const Columns = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 30px;
    flex: 1;

    @media (max-width: 450px) {
        padding: 20px 20px;
    }
`;

export const Box = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0px 90px;
    padding-bottom: 40px;

    @media (max-height: 600px) {
        padding: 0px 0px;
    }

    @media (max-width: 450px) {
        padding: 0px 0px;
    }
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
`;

export const Title = styled.div`
    font-weight: 900;
    font-size: 40px;
    margin: auto;
    margin-bottom: 0px;
    line-height: 43px;
    color: #12ff82;
    text-align: center;
    font-family: 'Druk Wide Bold', sans-serif;
    text-transform: uppercase;
    padding: 40px 0px;

    @media (max-height: 600px) {
        font-size: medium;
        line-height: 24px;
    }

    @media (max-width: 450px) {
        font-size: small;
    }
`;

export const Titre = styled.div`
    font-weight: 900;
    font-size: large;
    margin: auto;
    margin-bottom: 0px;
    line-height: 34px;
    color: #12ff82;
    text-align: center;
    font-family: 'Ace Lift Regular', sans-serif;
    text-transform: uppercase;

    @media (max-height: 600px) {
        font-size: medium;
        line-height: 24px;
    }

    @media (max-width: 450px) {
        font-size: small;
    }
`;

export const SubTitre = styled.div`
    font-weight: 900;
    font-size: medium;
    margin: auto;
    margin-top: 0px;
    color: white;
    font-family: 'Ace Lift Regular', sans-serif;
    text-transform: uppercase;

    @media (max-height: 600px) {
        margin: 10px auto;
        font-size: small;
    }

    @media (max-width: 450px) {
        font-size: x-small;
    }
`;
